import React, { useState, useEffect } from "react";
import axios from "axios";
import { Timeline, Spin, Descriptions } from "antd";
import { Comment } from "@ant-design/compatible";
import dayjs from "dayjs";

import styled from "styled-components";
import { Audit } from "../types";

const SCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 50px;

  > ul {
    min-width: 650px;
  }

  .ant-comment-inner {
    padding: 0;
  }
`;

const labelToColor = (label: string) => {
  switch (label) {
    default:
      return "blue";
  }
};

const labelToContent = (label: string) => {
  switch (label) {
    case "Create term":
      return "Submitted the transaction";
    case "Update term":
      return "Updated the transaction";
    case "Get term":
      return "Viewed the transaction";
    default:
      return "";
  }
};

const auditToContent = (audit: Audit) => {
  if (audit.label === "Update term") {
    const parsedDescription = JSON.parse(audit.description);
    //return (JSON.stringify(Object.keys(parsedDescription)));
    return (
      <Descriptions title={labelToContent(audit.label)} size={"small"} bordered>
        {Object.keys(parsedDescription).map((field, index) => (
          <Descriptions.Item
            span={3}
            label={
              field.charAt(0).toUpperCase() + field.slice(1).replace("_", " ")
            }
            key={index}
          >
            <b>Before:</b>
            <br />
            {parsedDescription[field].before}
            <br />
            <b>After:</b>
            <br />
            {parsedDescription[field].after}
            <br />
          </Descriptions.Item>
        ))}
      </Descriptions>
    );
  } else {
    return (
      <Descriptions
        title={labelToContent(audit.label)}
        size={"small"}
        bordered
      ></Descriptions>
    );
  }
};

const AuditsList = ({ audits }: { audits: Audit[] }) => (
  <Timeline mode="left">
    {audits.map((audit: Audit, i: number) => (
      // @ts-ignore
      <Timeline.Item key={i} color={labelToColor(audit.label)}>
        <Comment
          author={audit.user_id}
          content={auditToContent(audit)}
          datetime={dayjs(audit.date).format("Do MMM, YYYY, h:mm:ss a")}
        />
      </Timeline.Item>
    ))}
  </Timeline>
);

const AuditLog = ({ record }: { record: string }) => {
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`/audit?record=${record}&filter={"type":"response"}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          let outp = res.data.results;
          if (res.data.results.description) {
            outp.description = JSON.parse(res.data.results.description);
          }
          setAudits(outp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [record]);

  return (
    <SCommentWrapper>
      {isLoading ? (
        //loading
        <Spin size="large" />
      ) : (
        //loaded
        audits.length > 0 && <AuditsList audits={audits} />
      )}
    </SCommentWrapper>
  );
};

export default AuditLog;
