import React, { useEffect, useState } from "react";
import axios from "axios";

import { Button, Modal, Result, Spin } from "antd";

import styled from "styled-components";
import { AttachmentFile } from "../../types";

const SCentered = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
`;

const DraggerFileLoaderModal = ({
  file,
  isModalVisible,
  closeFileModal,
}: {
  file: AttachmentFile;
  isModalVisible: boolean;
  closeFileModal: () => void;
}) => {
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const openFile = (manuallyOpened?: boolean) => {
    const id = setTimeout(() => {
      if (!manuallyOpened) {
        window.open(file.url, "_blank");
        closeFileModal();
      }
    }, 3000);
    setTimeoutId(id);
  };

  useEffect(() => {
    setLoading(true);

    if (!file.uid) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    axios
      .post(`/upload/share`, {
        fileId: file.uid,
      })
      .then((res) => {
        if (res.status !== 200) {
          setError(true);
          return;
        } else {
          setLoading(false);
          openFile();
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return;
      });
  }, [file]);

  return (
    // @ts-ignore
    <Modal
      width={500}
      onCancel={() => closeFileModal()}
      closable={false}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={() => closeFileModal()}>
          {loading ? "Cancel" : "Close"}
        </Button>,
      ]}
      open={isModalVisible}
    >
      {isError ? (
        <Result
          status="error"
          subTitle={`There was an issue in providing access to: ${file.name}.`}
        />
      ) : loading ? (
        <SCentered>
          <p>
            Checking your access to: <b>{file.name}</b>
          </p>
          <Spin />
        </SCentered>
      ) : (
        <Result
          status="success"
          subTitle={`${file.name} will open in a new window automatically within 3 seconds.`}
          extra={[
            <a
              href={file.url}
              onClick={() => {
                openFile(true);
                clearTimeout(timeoutId);
                closeFileModal();
              }}
              target="_blank"
              rel="noreferrer"
            >
              File not opening? Click here.
            </a>,
          ]}
        />
      )}
    </Modal>
  );
};

export default DraggerFileLoaderModal;
