import React from "react";
import { Tag } from "antd";
import { UserRole } from "../enums";

const RoleTag = ({ role }: { role: UserRole }) => {
  const roleAndColorEnum = {
    club: {
      color: "blue",
      display: "Club",
    },
    admin: {
      color: "red",
      display: "Admin",
    },
    legal: {
      color: "purple",
      display: "Legal Team",
    },
  };

  const roleResult = roleAndColorEnum[role]
    ? roleAndColorEnum[role]
    : {
        color: "purple",
        display: "n/a",
      };

  return <Tag color={roleResult.color}>{roleResult.display}</Tag>;
};

export default RoleTag;
