import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, message, Popconfirm, Space, Table } from "antd";
import {
  DeleteOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import RoleTag from "../../components/RoleTag";
import TableToolbar from "../../components/TableToolbar";
import CompanyTag from "../../components/CompanyTag";
import styled from "styled-components";
import InviteUserModal from "../../components/modals/InviteUserModal";
import { connect } from "react-redux";
import { ColumnType } from "rc-table/lib/interface";
import { PageHeader } from "@ant-design/pro-components";
import { Auth } from "../../types";
import { UserRole } from "../../enums";

const routes = {
  items: [{ title: "Users" }, { title: "List" }],
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
`;

const UserListStaging = ({ auth }: { auth: Auth }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [sort, setSort] = useState({
    field: undefined,
    order: undefined,
  });
  const [filter, setFilter] = useState({});
  const [inviteModalVisible, setInviteModalVisible] = useState(false);

  const isUserAdmin = auth.user.role === UserRole.admin;

  const columns: ColumnType<any>[] = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 140,
    },
    {
      title: "Club",
      dataIndex: "company",
      key: "company",
      width: 175,
      render: (company: any) => <CompanyTag company={company} />,
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      width: 100,
      render: (role: UserRole) => <RoleTag role={role} />,
    },
  ];

  if (isUserAdmin) {
    columns.push({
      title: "Actions",
      dataIndex: "role",
      key: "action",
      width: 75,
      render: (_: any, record: { _id: string }) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you would like to delete this user?"
            key={4}
            icon={<DeleteOutlined />}
            onConfirm={() => deleteUser(record._id)}
          >
            <Button icon={<DeleteOutlined />}>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    });
  }
  const deleteUser = (userId: any) => {
    setLoading(true);
    const hide = message.loading("Deleting..", 1.0);

    if (!userId) return;

    axios
      .delete(`/user/${userId}`)
      .then(() => {
        fetchData(pagination);
        hide();
        setLoading(false);
        message.success("User deleted", 2.5);
      })
      .catch((e) => {
        hide();
        setLoading(false);
        message.error("Error with deleting user", 2.5);
      });
  };

  const fetchData = (pageParams: any, filters?: any, sorter?: any) => {
    if (sorter) {
      setSort({
        field: sorter.field,
        order: sorter.order,
      });
    }
    if (filters) {
      setFilter(filters);
    }

    setLoading(true);
    const hide = message.loading("Loading...");
    axios
      .get(
        `/user?page=${pageParams.current}&sort=${sort.field}&order=${
          sort.order
        }&filter=${JSON.stringify(filters)}`
      )
      .then((res) => {
        let outp = [];
        for (var i = 0; i < res.data.results.length; i++) {
          let item = {
            key: i,
            ...res.data.results[i],
          };
          outp.push(item);
        }

        setData(outp);
        setLoading(false);
        setPagination({
          ...pagination,
          current: res.data.page,
          total: res.data.rows,
        });
        hide();
      });
  };

  useEffect(() => {
    fetchData(pagination);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData(pagination, filters, sorter);
  };

  //build company arrays
  useEffect(() => {
    axios.get(`/company`).then((res) => {
      let outp = [];
      for (var i = 0; i < res.data.length; i++) {
        let item = {
          text: res.data[i].name,
          value: res.data[i]._id,
          display: res.data[i].name,
          logo: res.data[i].logo,
          key: i,
        };
        outp.push(item);
      }
      setCompanies(outp);
    });
  }, []);

  return (
    <>
      <InviteUserModal
        companies={companies}
        isModalVisible={inviteModalVisible}
        setIsModalVisible={setInviteModalVisible}
      />
      <SPageHeader
        title="Users"
        breadcrumb={routes}
        extra={[
          isUserAdmin && (
            <Button
              key={1}
              onClick={() => setInviteModalVisible(true)}
              icon={<UsergroupAddOutlined />}
            >
              Invite
            </Button>
          ),
        ]}
      />
      <SPageContent>
        <Card bodyStyle={{ paddingTop: "0" }} style={{ minWidth: "700px" }}>
          <TableToolbar
            title=""
            buttons={[
              <Button
                key={0}
                onClick={() => fetchData(pagination, filter, sort)}
                icon={<SyncOutlined />}
              ></Button>,
            ]}
          />
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </SPageContent>
    </>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const UserList = connect(mapStateToProps, null)(UserListStaging);

export default UserList;
