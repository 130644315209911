import { TransactionType } from "./enums";

export const getLabelForTransactionType = (transactionType: string) => {
  switch (transactionType) {
    case TransactionType.threshold:
      return "Threshold";
    case TransactionType.databank:
      return "Databank";
    case TransactionType.associated:
      return "Assoc. Party";
    default:
      return undefined;
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  if (!string) {
    return "";
  }

  return string?.length > 1
    ? string
        .split(" ")
        .map((s) => capitalize(s))
        .join(" ")
        .replace(/fc/gi, "FC")
    : string.constructor === String
    ? capitalize(string)
    : "";
};
