import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Button, Result } from "antd";

import { logoutUser } from "../actions";

const LogoutStaging = (props: any) => {
  const { logoutUser } = props;

  useEffect(() => {
    logoutUser();
  }, []);

  return (
    <Result
      status="success"
      title=""
      subTitle="You have been logged out successfully. Feel free to close this window."
      extra={[
        <Button type="primary" href="/" key="login">
          Return to Login
        </Button>,
      ]}
    />
  );
};

const mapDispatchToProps = {
  logoutUser,
};

const Logout = connect(null, mapDispatchToProps)(LogoutStaging);

export default Logout;
