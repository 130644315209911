import React from "react";
import { Avatar, Form, Select } from "antd";
import styled from "styled-components";

import BrandSectorOptions from "./BrandSectorOptions";

import getTerritoryInputOptions from "./getTerritoryInputOptions";
import fieldToJSX from "./fieldToJSX";

import getSubjectMatterOptions from "./getSubjectMatterOptions";
import { Company, FormField } from "../../types";

const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const SFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormFields = ({
  isClubUser,
  newRecord,
  canWrite,
  companies,
  index,
}: any) => {
  if (index === undefined) return <></>;

  const fieldsArr: FormField[][] = [
    [
      {
        name: "company",
        label: "Company",
        dontDisplay: isClubUser || !newRecord,
        tooltip: "Select a company this term is on behalf of.",
        rules: [{ required: true }],
        type: "select",
        select: {
          options:
            companies &&
            companies.map((company: Company, index: number) => {
              console.log(index);
              return (
                <Option
                  key={index}
                  value={company.value}
                  display={company.display}
                >
                  <SOptionLogoWrapper>
                    <Avatar
                      size="small"
                      src={`/club_logos/48/${company.logo}`}
                      alt="avatar"
                    />
                    {company.display}
                  </SOptionLogoWrapper>
                </Option>
              );
            }),
        },
      },
      {
        name: "title",
        label: "Title",
        rules: [{ required: true }],
        type: "text",
      },
      {
        name: "description",
        label: "Description",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 1000,
      },
      {
        name: "agreement_date",
        label: "Date of agreement",
        tooltip: "Insert date of the agreement you are submitting.",
        style: { width: "100%" },
        rules: [{ type: "object", required: true }],
        type: "date",
      },
      {
        name: "contracting_party",
        label: "Contracting party",
        tooltip: "Give full name of contracting party.",
        rules: [{ required: true }],
        type: "text",
      },
      {
        name: "contracting_party_jurisdiction",
        label: "Jurisdiction of contracting party",
        tooltip:
          "Please select the correct jurisdiction(s) from the drop-down menu.",
        rules: [{ required: true }],
        type: "select",
        select: {
          mode: "multiple",
          options: getTerritoryInputOptions(),
        },
      },
      {
        name: "parent_contracting_party",
        label: "Parent company",
        tooltip: "Give full name of entity.",
        type: "text",
      },
      {
        name: "brand",
        label: "Brand",
        rules: [{ required: true }],
        type: "text",
      },
      {
        name: "brand_sector",
        label: "Brand sector",
        tooltip:
          "Please select a sector from dropdown menu and choose 'Other' if the sector does not appear in the list.",
        rules: [{ required: true }],
        type: "cascader",
        cascader: {
          mode: "multiple",
          options: BrandSectorOptions,
        },
      },
      {
        name: "subject_matter",
        label: "Subject matter of agreement",
        tooltip:
          "Please select from the drop-down menu - use 'Other' if subject matter does not appear in the list.",
        rules: [{ required: true }],
        type: "select",
        select: {
          options: getSubjectMatterOptions(),
        },
      },
      {
        name: "associated_party",
        label: "Associated party",
        tooltip:
          "See definition of ‘Associated Party Transaction’ in Rule A.1.18 of the Premier League Rules, and Rules E.55 to E.58 of the Premier League Rules",
        rules: [{ required: true }],
        type: "radio",
        options: [
          {
            value: "a",
            label: "Yes",
          },
          {
            value: "b",
            label: "No",
          },
        ],
      },
      {
        name: "arms_length",
        label: "Deal at arm's length",
        tooltip:
          "See definition of ‘Arm’s Length’ in Rule A.1.13 of the Premier League Rules",
        rules: [{ required: true }],
        type: "radio",
        options: [
          {
            value: "a",
            label: "Yes",
          },
          {
            value: "b",
            label: "No",
          },
        ],
      },
    ],
    //tab 2, Summary
    [
      {
        name: "summary",
        label: "Summary of arrangement",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "commencement_date",
        label: "Date of commencement",
        tooltip: "State when the Transaction took effect.",
        style: { width: "100%" },
        rules: [{ type: "object", required: true }],
        type: "date",
      },
      {
        name: "term",
        label: "Term",
        tooltip: "State how long the deal is due to last for.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "renewal_provisions",
        label: "Renewal provisions",
        tooltip:
          "Describe any renewal provisions or options to extend the initial/current term of the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "strategic_rationale",
        label: "Strategic rationale",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 3, Fees
    [
      {
        name: "fees",
        label: "fees",
        tooltip: "fees",
        type: "format_row",
        entities: [
          {
            name: "fees_total",
            label: "Total fees",
            rules: [{ required: true }],
            type: "number",
            number: {
              formatter: (value: number) =>
                `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              parser: (value: string) => value.replace(/£\s?|(,*)/g, ""),
            },
          },
          {
            name: "fees_cash",
            label: "(of which cash)",
            rules: [{ required: true }],
            type: "number",
            number: {
              formatter: (value: any) =>
                `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              parser: (value: any) => value.replace(/£\s?|(,*)/g, ""),
            },
          },
          {
            name: "fees_in_kind",
            label: "(of which products/services)",
            rules: [{ required: true }],
            type: "number",
            number: {
              formatter: (value: any) =>
                `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              parser: (value: any) => value.replace(/£\s?|(,*)/g, ""),
            },
          },
        ],
      },
      {
        name: "fees_summary",
        label: "Fees breakdown",
        tooltip:
          "Please provide further details of the products and services (if relevant).",
        rules: [],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "fees_royalties",
        label: "Royalties share arrangements",
        tooltip:
          "Describe any royalty or revenue sharing arrangements and specify amounts in GBP.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "commercial_incentives",
        label: "Commercial incentives",
        tooltip:
          "Describe any other commercial incentives and specify amount in GBP.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "associated_fees",
        label: "Associated fees",
        tooltip:
          "Set out full list of any associated payments either paid or payable as part of the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 4, Assets and Partner Rights
    [
      {
        name: "partner_designations",
        label: "Partner designations",
        tooltip:
          "Describe any partner designations or rights or assets under the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "exclusive_categories",
        label: "Exclusive categories",
        tooltip:
          "Describe any exclusive categories of rights or assets under the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "non_exclusive_categories",
        label: "Non-exclusive categories",
        tooltip:
          "Describe any non-exclusive categories of rights or assets under the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "territories",
        label: "Territories",
        tooltip: "Select correct jurisdiction(s) from the drop-down menu.",
        rules: [{ required: true }],
        type: "select",
        select: {
          mode: "multiple",
          options: getTerritoryInputOptions(),
        },
      },
      {
        name: "assets_provided",
        label: "Assets provided by club",
        tooltip: "List any assets provided by the Club here.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "services_provided",
        label: "Services provided by club",
        tooltip: "List any services provided by the Club here.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "partner_rights",
        label: "Partner rights",
        tooltip:
          "Set out the partner’s rights here - short bullet points are fine.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "licensing_rights",
        label: "Licensing rights",
        tooltip: "List licencing rights here (if any).",
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "other_rights",
        label: "Other rights",
        tooltip:
          "Describe other rights arising under the Transaction documents (if any).",
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 5, Arm's Length Confirmation (threshold)
    [
      {
        name: "competitive_process",
        label: "Competitive process",
        tooltip:
          "Brief details of any tender/competitive process undertaken prior to the Transaction",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "competing_bidders",
        label: "Competing bidders",
        tooltip:
          "Details of any competing bidders (e.g. number and type of bidders (anonymised if required))",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "competing_bids",
        label: "Range of losing bids",
        tooltip:
          "Range of losing bids (anonymised if required, or as a percentage difference from the winning bid) ",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "transaction_nature",
        label: "Nature of the transaction",
        tooltip:
          "Please describe the nature of the Transaction (e.g. is it a new deal/partner or is this a renewal? Is the Club entering a new brand sector, or is this an existing sector?)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "comparable_deals_value",
        label: "Value of comparable deals",
        tooltip:
          "Please provide brief details of the approximate value of any comparable previous deal (if applicable)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "source_of_relationship",
        label: "Source of relationship",
        tooltip:
          "Brief description of how the relationship was sourced, and how that worked in practice with any competitive process described above",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "compliance_checks",
        label: "Compliance checks",
        tooltip:
          "Confirmation of any compliance checks performed (e.g. anti-bribery, money laundering)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
    //tab 6, Associated Party (associated)
    [
      {
        name: "associated_party_relationship",
        label: "Associated Party relationship",
        tooltip:
          "Description of the Associated Party relationship between the Club and commercial partner",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "associated_party_fmv",
        label: "Information relevant to Fair Market Value",
        tooltip:
          "Information relevant to demonstrate the Fair Market Value of the Transaction",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
  ];

  return (
    <>
      {fieldsArr[index].map((field) => {
        if (field.dontDisplay)
          return <React.Fragment key="none"></React.Fragment>;

        if (field.type === "format_row")
          return (
            <SFieldRow key={field.name}>
              {field.entities?.map((field: FormField) =>
                renderField(field, canWrite)
              )}
            </SFieldRow>
          );

        return renderField(field, canWrite);
      })}
    </>
  );
};

const renderField = (field: FormField, canWrite: boolean) => (
  <Form.Item
    name={field.name}
    key={field.name}
    tooltip={field.tooltip}
    style={field.style}
    label={field.label}
    rules={field.rules}
  >
    {fieldToJSX(field, canWrite)}
  </Form.Item>
);

export default FormFields;
