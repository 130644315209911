import React, { useEffect, useState } from "react";
import type { UploadProps, UploadFile } from "antd";
import {
  Button,
  Divider,
  Form,
  message,
  Popconfirm,
  Spin,
  Tabs,
  Upload,
} from "antd";
import {
  DeleteOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import AuditLog from "../components/AuditLog";

import Comments from "../components/Comments";

import DraggerFileLoaderModal from "./modals/DraggerFileLoaderModal";
import FormFields from "./enums/FormFields";

import styled from "styled-components";
import axios from "axios";
import {
  AttachmentFile,
  AttachmentFileRaw,
  BespokeAttachments,
  Company,
  FormField,
} from "../types";
import { AttachmentType, UserRole } from "../enums";
import { UploadChangeParam } from "antd/es/upload/interface";

type HandleChangeWithAttachmentType = (
  info: UploadChangeParam<UploadFile<AttachmentFile>>,
  type: AttachmentType
) => void;

const { Dragger } = Upload;

const SFormWrapper = styled.div`
  max-width: 900px;
  position: relative;
  margin: 20px auto auto auto;
`;

const SBespokeAttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  > span {
    flex: 1;
  }
`;

const SBespokeAttachment = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 0;
  justify-content: flex-start;
`;

require("dotenv").config();

const { REACT_APP_API_URL } = process.env;

const PartnershipForm = ({
  newRecord,
  userRole,
  companies,
  onFinish,
  attachmentList,
  setAttachmentList,
  bespokeAttachments,
  setBespokeAttachments,
  form,
  tempId,
  id,
  canWrite,
  isThreshold,
  isAssociatedParty,
  scrollToFirstError,
}: {
  newRecord: boolean;
  userRole: UserRole;
  companies?: Company[];
  onFinish: (fieldValues: FormField[]) => void;
  attachmentList?: AttachmentFile[];
  setAttachmentList?: any;
  bespokeAttachments?: {
    unredacted_agreement: AttachmentFile[];
    redacted_agreement: AttachmentFile[];
    pl_reports: AttachmentFile[];
  };
  setBespokeAttachments?: (bespokenAttachments: BespokeAttachments) => void;
  form: any;
  tempId?: string;
  id?: string;
  canWrite: boolean;
  isThreshold?: boolean;
  isAssociatedParty?: boolean;
  scrollToFirstError?: boolean;
}) => {
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deletingFile, setDeletingFile] = useState<AttachmentFile>(
    {} as AttachmentFile
  );
  const [fileModalFile, setFileModalFile] = useState({} as AttachmentFile);
  const [defaultFileList, setDefaultFileList] = useState(
    [] as AttachmentFile[]
  );
  const [redactedFileList, setRedactedFileList] = useState(
    [] as AttachmentFile[]
  );
  const [unredactedFileList, setUnredactedFileList] = useState(
    [] as AttachmentFile[]
  );
  const [plReportsFileList, setplReportsFileList] = useState(
    [] as AttachmentFile[]
  );

  const isAdminUser = userRole === UserRole.admin;
  const isLegalUser = userRole === UserRole.legal;
  const isClubUser = userRole === UserRole.club;

  const loadFileModal = (file: any) => {
    setFileModalFile(file);
    setFileModalVisible(true);
  };

  const closeFileModal = () => {
    setFileModalFile({} as AttachmentFile);
    setFileModalVisible(false);
  };

  useEffect(() => {
    setDefaultFileList(attachmentList);
    setRedactedFileList(bespokeAttachments.redacted_agreement);
    setUnredactedFileList(bespokeAttachments.unredacted_agreement);
    setplReportsFileList(bespokeAttachments.pl_reports);
  }, [attachmentList, bespokeAttachments]);

  const handleChange: HandleChangeWithAttachmentType = (
    info: UploadChangeParam<UploadFile<AttachmentFile>>,
    type: AttachmentType
  ) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);

      if (info.file.response) {
        if (info.file.response.url) info.file.url = info.file.response.url;
        if (info.file.response.name) info.file.name = info.file.response.name;
        if (info.file.response.id) info.file.uid = info.file.response.id;
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    if (type === AttachmentType.redacted_agreement) {
      setBespokeAttachments({
        ...bespokeAttachments,
        redacted_agreement: [...(info.fileList as unknown as AttachmentFile[])],
      });

      let newFileList = [...info.fileList];
      setRedactedFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.unredacted_agreement) {
      setBespokeAttachments({
        ...bespokeAttachments,
        unredacted_agreement: [...info.fileList],
      } as unknown as BespokeAttachments);

      let newFileList = [...info.fileList];
      setUnredactedFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.pl_reports) {
      setBespokeAttachments({
        ...bespokeAttachments,
        pl_reports: [...info.fileList],
      } as unknown as BespokeAttachments);

      let newFileList = [...info.fileList];
      setplReportsFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.default) {
      let newFileList = [...info.fileList];
      setDefaultFileList(newFileList as unknown as AttachmentFile[]);
      setAttachmentList(newFileList);
    } else {
      throw Error(`Attachment type not recognised, ${type}`);
    }
  };

  const onDeleteAttachment = async () => {
    // just a sanity check, should not be needed as
    // the delete button is not visible if !isAdminUser
    if (!isAdminUser) {
      return;
    }
    setIsLoading(true);
    if (deletingFile) {
      return axios
        .post(`/upload/delete/${deletingFile.uid}`, { role: userRole })
        .then(async (res) => {
          if (res.status !== 200) {
            setIsLoading(false);
            return false;
          } else {
            setIsLoading(false);
            message.success(`${deletingFile.name} file deleted successfully`);

            const getAttachments = await axios.get(`/upload?record=${id}`);
            if (getAttachments.data) {
              let defaultAtt = [] as AttachmentFile[];
              let redactedAtt = [] as AttachmentFile[];
              let unredactedAtt = [] as AttachmentFile[];
              let plReportAtt = [] as AttachmentFile[];

              const newAttachments = getAttachments.data.map(
                (attachment: AttachmentFileRaw) => ({
                  ...attachment,
                  name: attachment.file_name,
                  uid: attachment._id,
                })
              );

              newAttachments.forEach((att: AttachmentFile) => {
                if (att.type === AttachmentType.redacted_agreement)
                  redactedAtt.push(att);
                else if (att.type === AttachmentType.unredacted_agreement)
                  unredactedAtt.push(att);
                else if (att.type === AttachmentType.pl_reports)
                  plReportAtt.push(att);
                else defaultAtt.push(att);
              });
              setDefaultFileList(defaultAtt);
              setRedactedFileList(redactedAtt);
              setUnredactedFileList(unredactedAtt);
              setplReportsFileList(plReportAtt);
              setBespokeAttachments({
                unredacted_agreement: unredactedAtt,
                redacted_agreement: redactedAtt,
                pl_reports: plReportAtt,
              });
              setAttachmentList(defaultAtt);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const baseUploadProps = {
    name: "file",
    onPreview: (file: UploadFile<AttachmentFile>) => {
      setFileModalVisible(true);
      setFileModalFile(file as unknown as AttachmentFile);
    },
    action: `${REACT_APP_API_URL}/upload`,
    headers: {
      Authorization: localStorage.cdToken,
    },
    maxCount: 10,
    disabled: !canWrite || isLoading,
    multiple: true,
    showUploadList: isLoading
      ? false
      : {
          showRemoveIcon: !!isAdminUser,
          removeIcon: (
            <Popconfirm
              title="Are you sure you would like to delete this attachment?"
              key="delete-attachment"
              icon={<DeleteOutlined />}
              onConfirm={onDeleteAttachment}
            >
              <DeleteOutlined />
            </Popconfirm>
          ),
        },
    async onRemove(file: UploadFile<AttachmentFile>) {
      setDeletingFile(file as unknown as AttachmentFile);
      // prevent default, because we have to show the confirmation dialogue
      return false;
    },
    beforeUpload: (file: any) => {
      let upload = true;
      if (file.size > 50000000) {
        message.error(`Attachment size exceeds the allowable limit of 50MB.`);
        upload = false;
      }

      const mimeBlockList = [
        "application/bat",
        "application/octet-stream",
        "application/postscript",
        "application/x-bat",
        "magnus-internal/cgi",
      ];
      if (mimeBlockList.includes(file.type)) {
        message.error(`Uploads of file type ${file.type} are not supported.`);
        upload = false;
      }

      return upload ? true : Upload.LIST_IGNORE;
    },
  };

  const defaultFilesUploadProps: UploadProps = {
    ...baseUploadProps,
    fileList: defaultFileList,
    onChange: (info: UploadChangeParam) =>
      handleChange(info, AttachmentType.default),
    data: {
      id: form.getFieldValue("_id") || tempId,
      company: form.getFieldValue("company") || "",
      newRecord: newRecord,
      type: AttachmentType.default,
    },
  };

  const unredactedFilesProps = {
    ...baseUploadProps,
    fileList: unredactedFileList,
    onChange: (info: UploadChangeParam) =>
      handleChange(info, AttachmentType.unredacted_agreement),
    multiple: false,
    data: {
      id: form.getFieldValue("_id") || tempId,
      company: form.getFieldValue("company") || "",
      newRecord: newRecord,
      type: AttachmentType.unredacted_agreement,
    },
  };

  const redactedFilesProps = {
    ...baseUploadProps,
    fileList: redactedFileList,
    onChange: (info: UploadChangeParam) =>
      handleChange(info, AttachmentType.redacted_agreement),
    multiple: false,
    data: {
      id: form.getFieldValue("_id") || tempId,
      company: form.getFieldValue("company") || "",
      newRecord: newRecord,
      type: AttachmentType.redacted_agreement,
    },
  };

  const plReportsUploadProps = {
    ...baseUploadProps,
    fileList: plReportsFileList,
    onChange: (info: UploadChangeParam) =>
      handleChange(info, AttachmentType.pl_reports),
    data: {
      id: form.getFieldValue("_id") || tempId,
      company: form.getFieldValue("company") || "",
      newRecord: newRecord,
      type: AttachmentType.pl_reports,
    },
  };

  return (
    <>
      <Form
        form={form}
        name="term"
        onFinish={onFinish}
        scrollToFirstError={scrollToFirstError}
        colon={false}
        layout="vertical"
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          items={[
            {
              key: "details",
              label: "Details",
              children: (
                <SFormWrapper>
                  <FormFields
                    canWrite={canWrite}
                    companies={companies}
                    isClubUser={isClubUser}
                    newRecord={newRecord}
                    index={0}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "summary",
              label: "Summary",
              children: (
                <SFormWrapper>
                  <FormFields
                    canWrite={canWrite}
                    companies={companies}
                    isClubUser={isClubUser}
                    newRecord={newRecord}
                    index={1}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "fees",
              label: "Fees",
              children: (
                <SFormWrapper>
                  <FormFields
                    canWrite={canWrite}
                    companies={companies}
                    isClubUser={isClubUser}
                    newRecord={newRecord}
                    index={2}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "assets and partner rights",
              label: "Assets and Partner Rights",
              children: (
                <SFormWrapper>
                  <FormFields
                    canWrite={canWrite}
                    companies={companies}
                    isClubUser={isClubUser}
                    newRecord={newRecord}
                    index={3}
                  />
                </SFormWrapper>
              ),
            },
            isThreshold && {
              key: "arms length confirmation",
              label: "Arm's Length Confirmation",
              children: (
                <SFormWrapper>
                  <FormFields
                    canWrite={canWrite}
                    companies={companies}
                    isClubUser={isClubUser}
                    newRecord={newRecord}
                    index={4}
                  />
                </SFormWrapper>
              ),
            },
            isAssociatedParty && {
              key: "associated party relationship",
              label: "Associated Party Relationship",
              children: (
                <SFormWrapper>
                  <FormFields
                    canWrite={canWrite}
                    companies={companies}
                    isClubUser={isClubUser}
                    newRecord={newRecord}
                    index={5}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "attachments",
              label: "Attachments",
              children: (
                <>
                  {isAssociatedParty && (
                    <>
                      <SBespokeAttachmentsWrapper>
                        {/* unredacted*/}
                        <SBespokeAttachment>
                          <div style={{ height: "185px" }}>
                            <h1>Unredacted agreement</h1>
                            <p className="ant-upload-text">
                              Please upload the documentation to which this
                              Associated Party Transaction relates with no
                              redactions applied to information relevant to the
                              Transaction.
                            </p>
                          </div>
                          {isLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Upload
                              listType="picture"
                              {...unredactedFilesProps}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )}

                          {/* redacted*/}
                        </SBespokeAttachment>
                        <SBespokeAttachment>
                          <div style={{ height: "185px" }}>
                            <h1>Redacted agreement</h1>

                            <p
                              className="ant-upload-text"
                              style={{ marginBottom: "1em" }}
                            >
                              Please upload the documentation to which this
                              Associated Party Transaction relates with all
                              references to any specific fees or other
                              consideration payable to or by the Club, Player,
                              Manager or Senior Official redacted, in accordance
                              with the applicable Fair Market Value Assessment
                              Protocol.
                            </p>
                          </div>

                          {isLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Upload listType="picture" {...redactedFilesProps}>
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )}
                        </SBespokeAttachment>
                      </SBespokeAttachmentsWrapper>
                      <Divider />
                    </>
                  )}

                  <Dragger listType="picture" {...defaultFilesUploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>

                    <p className="ant-upload-text">Attachments</p>

                    <p className="ant-upload-hint">
                      {isAssociatedParty
                        ? "Please upload any additional information you consider may be relevant to the Board’s Fair Market Value Assessment of the Associated Party Transaction. "
                        : "Click or drag files to this area to upload."}
                    </p>
                    {isLoading && <Spin size="small" />}
                  </Dragger>
                </>
              ),
            },
            !newRecord && {
              key: "journal",
              label: "Journal",
              children: <Comments record={id} />,
            },
            !newRecord && {
              key: "audit",
              label: "Audit",
              children: <AuditLog record={id} />,
            },
            isAdminUser && {
              key: "premier league reports",
              label: "Premier League Reports",
              children: (
                <div>
                  <Dragger listType="picture" {...plReportsUploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>

                    <p className="ant-upload-text">Premier League Reports</p>

                    <p className="ant-upload-hint">
                      Click or drag files to this area to upload.
                    </p>
                    {isLoading && <Spin size="small" />}
                  </Dragger>
                </div>
              ),
            },
          ]}
        />
      </Form>
      <DraggerFileLoaderModal
        isModalVisible={fileModalVisible}
        closeFileModal={closeFileModal}
        file={fileModalFile}
      />
    </>
  );
};

export default PartnershipForm;
