import React from "react";
import { Cascader, DatePicker, Radio, Select, Input, InputNumber } from "antd";
import { FilterField, FormField } from "../../types";

const { RangePicker } = DatePicker;

const cascaderSearch = (inputValue: string, path: string[]) =>
  path.some(
    (option: any) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );

const fieldToJSX = (field: FormField | FilterField, canWrite: boolean) => {
  if (!canWrite) canWrite = true;

  let fieldContents;

  switch (field.type) {
    case "text":
      fieldContents = <Input disabled={!canWrite} />;
      break;
    case "textarea":
      fieldContents = (
        <Input.TextArea
          showCount
          disabled={!canWrite}
          maxLength={field.maxLength}
        />
      );
      break;
    case "date":
      fieldContents = <DatePicker disabled={!canWrite} />;
      break;
    case "daterange":
      fieldContents = (
        <div>
          <RangePicker />
        </div>
      );
      break;
    case "select":
      fieldContents = (
        <Select
          mode={field.select.mode}
          allowClear
          disabled={!canWrite}
          style={{ width: "100%" }}
          placeholder="Please select"
        >
          {field.select.options}
        </Select>
      );
      break;
    case "cascader":
      fieldContents = (
        <Cascader
          changeOnSelect
          disabled={!canWrite}
          options={field.cascader.options}
          placeholder="Please select"
          // @ts-ignore
          showSearch={cascaderSearch}
        />
      );
      break;
    case "radio":
      fieldContents = (
        <Radio.Group disabled={!canWrite}>
          {field.options.map((option: any, index: any) => (
            <Radio key={index} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      );
      break;
    case "number":
      fieldContents = (
        <InputNumber
          style={{ width: "100%" }}
          disabled={!canWrite}
          min={field.name === "number" ? 1000100 : null}
          formatter={field.name !== "number" ? field.number.formatter : null}
          parser={field.name !== "number" ? field.number.parser : null}
        />
      );
      break;
    default:
      fieldContents = <Input disabled={!canWrite} />;
      break;
  }

  return fieldContents;
};

export default fieldToJSX;
