import React from "react";
import jwt_decode from "jwt-decode";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/msal";
import { Provider } from "react-redux";
import axios from "axios";
import {
  DecodedToken,
  logoutUser,
  setAuthToken,
  setCurrentUser,
} from "./actions";
import store from "./utils/store";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import { cdbTheme } from "./cdbTheme";

require("dotenv").config();

const { REACT_APP_API_URL } = process.env;

axios.defaults.baseURL = REACT_APP_API_URL;

const msalInstance = new PublicClientApplication(msalConfig);

// Check for token to keep user logged in
if (localStorage.cdToken) {
  const token = localStorage.cdToken;
  setAuthToken(token);
  const decoded = jwt_decode(token) as DecodedToken;
  //login user
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    //@ts-ignore;
    store.dispatch(logoutUser());
    window.location.href = "./";
  }
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ConfigProvider theme={{ token: cdbTheme }}>
        <App />
      </ConfigProvider>
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
