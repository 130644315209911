import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Layout } from "antd";
import Sidebar from "../components/Sidebar";
import AvatarDropdown from "../components/AvatarDropdown";
import { connect } from "react-redux";
import { Auth } from "../types";

const { Header, Content, Footer } = Layout;

const SHeader = styled(Header)`
  padding: 0;
  height: 48px;
  line-height: 48px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 1;
`;

const SHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  padding: 0 16px;
`;

const DashboardLayoutStaging = ({ auth }: { auth: Auth }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />

      <Layout className="site-layout">
        <SHeader style={{}}>
          <SHeaderContent>
            <AvatarDropdown />
          </SHeaderContent>
        </SHeader>

        <Content>
          <Outlet />
        </Content>

        <Footer style={{ textAlign: "center" }}>Premier League</Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const DashboardLayout = connect(mapStateToProps)(DashboardLayoutStaging);

export default DashboardLayout;
