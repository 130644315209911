import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";

import styled from "styled-components";
import { Auth, Company } from "../../types";
import { TransactionType, UserRole } from "../../enums";

const { Paragraph, Link } = Typography;
const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const DraftTransactionModalStaging = ({
  auth,
  companies,
  isModalVisible,
  setIsModalVisible,
  newDraftType,
}: {
  auth: Auth;
  companies: Company[];
  isModalVisible: boolean;
  newDraftType: TransactionType;
  setIsModalVisible: (isVisible: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const isThreshold = newDraftType === TransactionType.threshold;
  const isAssociatedParty = newDraftType === TransactionType.associated;

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const saveData = (data: any) => {
    setLoading(true);

    const hide = message.loading("Saving..", 1.0);

    let input = {
      ...data,
    };

    input.type = isThreshold
      ? TransactionType.threshold
      : isAssociatedParty
      ? TransactionType.associated
      : TransactionType.databank;

    axios.post(`/term`, input).then((res) => {
      hide();
      setLoading(false);
      message.success("Submitted", 2.5);
      navigate(`/transaction/${res.data.newTerm._id}`);
    });
  };
  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    saveData(values);
  };
  const handleOk = () => {
    form.validateFields().then(() => form.submit());
  };
  const handleCancel = () => {
    setLoading(false);
    setIsModalVisible(false);
  };

  return (
    // @ts-ignore
    <Modal
      title={`Create Draft ${
        isThreshold
          ? "Threshold"
          : isAssociatedParty
          ? "Associated Party"
          : "Databank"
      } Transaction`}
      width={750}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Create Draft
        </Button>,
      ]}
    >
      {isThreshold ? (
        <>
          <Paragraph>
            Please select this option if submitting a new Threshold Transaction
            (please refer to the Premier League Rules for the full definitions
            but in summary, this includes any Transaction with a Third Party
            (that is not an Associated Party), the Averaged Annual Value of
            which exceeds £1 million).
          </Paragraph>

          <Paragraph>
            If you have any queries or are unsure of which option to select
            please contact the League via{" "}
            <Link href="mailto:financialcontrols@premierleague.com">
              financialcontrols@premierleague.com
            </Link>
          </Paragraph>
        </>
      ) : isAssociatedParty ? (
        //associated party description

        <>
          <Paragraph>
            Please select this option if submitting a new Associated Party
            Transaction (please refer to the Premier League Rules for the full
            definitions but in summary, this applies to any Transaction of any
            value that is directly or indirectly between: (1) a Club, Player,
            Manager or Senior Official; and (2) an Associated Party of the
            Club).
          </Paragraph>

          <Paragraph>
            If you have any queries or are unsure of which option to select
            please contact the League via{" "}
            <Link href="mailto:financialcontrols@premierleague.com">
              financialcontrols@premierleague.com
            </Link>
          </Paragraph>
        </>
      ) : (
        //databank description
        <>
          <Paragraph>
            Please select this option if submitting a new Databank Transaction
            (please refer to the Premier League Rules for the full definitions
            but in summary, any Transaction between the Club and a third party
            pursuant to which a payment, fee or value in kind is provided or to
            be provided to the Club, with an Averaged Annual Value between
            £100,000 and £1 million per annum).
          </Paragraph>

          <Paragraph>
            Any new Transaction worth over £1m per annum should be submitted as
            a Threshold Transaction.
          </Paragraph>

          <Paragraph>
            If you have any queries or are unsure of which option to select
            please contact the League via{" "}
            <Link href="mailto:financialcontrols@premierleague.com">
              financialcontrols@premierleague.com
            </Link>
          </Paragraph>
        </>
      )}
      <Form
        form={form}
        name="newTerm"
        layout="vertical"
        onFinish={onFormFinish}
        scrollToFirstError
      >
        {auth.user.role === UserRole.admin && (
          <Form.Item
            name="company"
            tooltip="Select a company this term is on behalf of."
            label="Company"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="display"
              filterOption={(input, option) =>
                option.display.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {companies.map((company: Company, index: number) => (
                <Option
                  key={index}
                  value={company.value}
                  display={company.display}
                >
                  <SOptionLogoWrapper>
                    <Avatar
                      size="small"
                      src={`/club_logos/48/${company.logo}`}
                      alt="avatar"
                    />
                    {company.display}
                  </SOptionLogoWrapper>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input.TextArea showCount maxLength={1000} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const DraftTransactionModal = connect(
  mapStateToProps,
  null
)(DraftTransactionModalStaging);

export default DraftTransactionModal;
