import React from "react";
import { Tag } from "antd";
import { TransactionStatus } from "../enums";

const StateTag = ({ state }: { state: TransactionStatus }) => {
  const getDisplay = () => {
    switch (state) {
      case TransactionStatus.draft:
        return "Draft";
      case TransactionStatus.pending:
        return "Pending";
      case TransactionStatus.rejected:
        return "Rejected";
      case TransactionStatus.approved:
        return "Approved";
      case TransactionStatus.submitted:
        return "Submitted";
      case TransactionStatus.historic:
        return "Historical";
    }
  };

  const getColor = () => {
    switch (state) {
      case TransactionStatus.draft:
        return "blue";
      case TransactionStatus.pending:
        return "orange";
      case TransactionStatus.rejected:
        return "red";
      case TransactionStatus.approved:
        return "green";
      case TransactionStatus.submitted:
        return "green";
      case TransactionStatus.historic:
        return "gray";
    }
  };

  const stateEnum = Object.fromEntries(
    Object.keys(TransactionStatus).map((status) => [
      [status],
      {
        color: getColor(),
        display: getDisplay(),
      },
    ])
  );

  const stateResult = stateEnum[state] || {
    color: "blue",
    display: "n/a",
  };

  return <Tag color={stateResult.color}>{stateResult.display}</Tag>;
};

export default StateTag;
