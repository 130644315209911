import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";

import { Button, Card, Dropdown, message, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { PageHeader } from "@ant-design/pro-components";
import {
  DownOutlined,
  ExportOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  PlusOutlined,
  SyncOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import StateTag from "../../components/StateTag";

import CompanyTag from "../../components/CompanyTag";
import TableToolbar from "../../components/TableToolbar";
import TypeTag from "../../components/TypeTag";
import DraftTransactionModal from "../../components/modals/DraftTransactionModal";
import ImportDataModal from "../../components/modals/ImportDataModal";
import FilterCard from "../../components/FilterCard";

import styled from "styled-components";
import { connect } from "react-redux";
import { Auth, Company, Filter, Transaction } from "../../types";
import { TablePaginationConfig } from "antd/es/table/interface";
import { TransactionType, UserRole } from "../../enums";
import { clubArray } from "../../components/enums/getClubInputOptions";

const routes = {
  items: [{ title: "Transactions" }, { title: "List" }],
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TransactionListStaging = ({ auth }: { auth: Auth }) => {
  const [data, setData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<Transaction[]>([]);
  const [pageCount, setPageCount] = useState({
    total: 0,
    startWindow: 0,
    endWindow: 0,
  });

  const getCachedPage = () => {
    return localStorage.transactionPage ? localStorage.transactionPage : 1;
  };
  const getCachedPageSize = () => {
    return localStorage.transactionPageSize
      ? localStorage.transactionPageSize
      : 10;
  };
  const [pagination, setPagination] = useState({
    current: getCachedPage(),
    pageSize: getCachedPageSize(),
    total: 0,
  });

  const [sort, setSort] = useState({
    field: undefined,
    order: undefined,
  });

  const getCachedFilter = () => {
    let parsedFilter = {} as Filter;

    if (localStorage.transactionFilter) {
      const filterJSON = localStorage.transactionFilter;
      parsedFilter = JSON.parse(filterJSON);

      delete parsedFilter.submission_date;
      delete parsedFilter.submitted_to_pl_date;
      delete parsedFilter.commencement_date;
      delete parsedFilter.agreement_date;

      return parsedFilter;
    } else {
      return {};
    }
  };

  const [filter, setFilter] = useState<Filter>(getCachedFilter());
  const [searchedText, setSearchedText] = useState("");
  const [companies, setCompanies] = useState([] as Company[]);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [termModalVisible, setTermModalVisible] = useState(false);

  const [newDraftType, setNewDraftType] = useState<TransactionType>();

  const navigate = useNavigate();

  const isUserAdmin = auth.user.role === UserRole.admin;
  const isUserClub = auth.user.role === UserRole.club;

  const columns: ColumnType<any>[] = [
    {
      title: "Tracking ID",
      dataIndex: "number",
      key: "number",
      width: 140,
      render: (text: any, record: any) => (
        <a href={`/transaction/${encodeURIComponent(record._id)}`}>CDB{text}</a>
      ),
      sorter: true,
      fixed: "left",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      render: (type: any) => <TypeTag type={type} />,
      width: 100,
      filteredValue: [filter && filter["type"]] || null,
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      filteredValue: [filter && filter["title"]] || null,
      render: (text: any) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      sorter: true,
    },
    {
      title: "Club",
      dataIndex: "company",
      key: "company",
      width: 175,
      render: (company: any) => <CompanyTag company={company} />,
      sorter: true,
    },
    {
      title: "Contracting party",
      dataIndex: "contracting_party",
      key: "contracting_party",
      width: 200,
      render: (text: any) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      sorter: true,
    },
    {
      title: "Status",
      key: "state",
      dataIndex: "state",
      render: (state: any) => <StateTag state={state} />,
      sorter: true,
      width: 100,
    },
    {
      title: "Fees (£)",
      dataIndex: "fees_total",
      key: "fees_total",
      width: 150,
      sorter: true,
      render: (fees_total: any) =>
        fees_total && `£${fees_total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Date of agreement",
      dataIndex: "agreement_date",
      key: "agreement_date",
      width: 200,
      sorter: true,
      render: (agreement_date: any) =>
        agreement_date ? dayjs(agreement_date).format("Do MMM, YYYY") : "",
    },
    {
      title: "Created date",
      dataIndex: "submission_date",
      key: "submission_date",
      width: 200,
      sorter: true,
      render: (submission_date: any) =>
        submission_date ? dayjs(submission_date).format("Do MMM, YYYY") : "",
    },
    {
      title: "Submitted date",
      dataIndex: "submitted_to_pl_date",
      key: "submitted_to_pl_date",
      width: 200,
      sorter: true,
      render: (submitted_to_pl_date: any) =>
        submitted_to_pl_date
          ? dayjs(submitted_to_pl_date).format("Do MMM, YYYY")
          : "",
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <Button href={`/transaction/${encodeURIComponent(record._id)}`}>
          View
        </Button>
      ),
      fixed: "right",
    },
  ];

  const fetchData = (
    pageParams: any,
    filtersInp: Filter,
    sorter?: { field?: keyof Filter; order?: number }
  ) => {
    //pagination
    if (pageParams && pageParams.current !== null)
      handlePageChange(pageParams.current, pageParams.pageSize);

    //filters
    let filters = {} as Filter;
    if (filtersInp) {
      Object.keys(filtersInp).forEach((field) => {
        if (filtersInp[field as keyof Filter]) {
          //@ts-ignore
          filters[field as keyof Filter] = filtersInp[field as keyof Filter];
        }
      });
      handleFilterChange(filters);
    }

    //sorting
    if (sorter) {
      setSort({
        field: sorter.field,
        order: sorter.order,
      });
    }

    setLoading(true);
    const hide = message.loading("Loading..");
    const queryParams: any = [];
    if (pageParams.current) queryParams.push(`page=${pageParams.current}`);
    if (pageParams.pageSize)
      queryParams.push(`pageSize=${pageParams.pageSize}`);
    if (sorter && sorter.field) queryParams.push(`sort=${sorter.field}`);
    if (sorter && sorter.order) queryParams.push(`order=${sorter.order}`);
    if (filters && Object.keys(filters).length !== 0)
      queryParams.push(`filter=${encodeURIComponent(JSON.stringify(filters))}`);
    axios.get(`/term?${queryParams.join("&")}`).then((res) => {
      let outp = [];
      for (let i = 0; i < res.data.results.length; i++) {
        let item = {
          key: i,
          ...res.data.results[i],
        };
        outp.push(item);
      }

      setData(outp);
      setLoading(false);
      setPagination({
        ...pagination,
        pageSize: queryParams.pageSize,
        current: res.data.page,
        total: res.data.rows,
      });

      setPageCount({
        total: res.data.rows,
        startWindow: Math.min(
          res.data.rows,
          ((res.data.page || 1) - 1) * (queryParams.pageSize || 10) + 1
        ),
        endWindow: Math.min(
          res.data.rows,
          res.data.page * (queryParams.pageSize || 10)
        ),
      });
      hide();
    });
  };

  //create new transaction process
  const showTermModal = () => {
    setTermModalVisible(true);
  };

  const handleCreateNewMenuClick = (event: { key: TransactionType }) => {
    if (event.key === TransactionType.databank) {
      setNewDraftType(TransactionType.databank);
    } else if (event.key === TransactionType.threshold) {
      setNewDraftType(TransactionType.threshold);
    } else if (event.key === TransactionType.associated) {
      setNewDraftType(TransactionType.associated);
    }
    showTermModal();
  };

  // export CSV process
  const headers = [
    { label: "Tracking ID", key: "trackingId" },
    { label: "Organisation", key: "organisation" },
    { label: "Transaction type", key: "transactionType" },
    { label: "Title", key: "title" },
    { label: "Contracting party", key: "contractingParty" },
    { label: "Status", key: "status" },
    { label: "Fees (in kind)", key: "feesInKind" },
    { label: "Fees (cash)", key: "feesCash" },
    { label: "Fees (total)", key: "feesTotal" },
    { label: "Date of agreement", key: "dateOfAgreement" },
    { label: "Created date", key: "createdDate" },
    { label: "Submitted date", key: "submittedDate" },
  ];

  const fetchCsvData = async () => {
    const queryParams = [];
    if (filter && Object.keys(filter).length !== 0) {
      queryParams.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);
    }
    let outp = [] as Transaction[];

    await axios.get("/term/all").then((res) => {
      for (let i = 0; i < res.data.results.length; i++) {
        let item = {
          key: i,
          ...res.data.results[i],
        };
        outp.push(item);
      }
    });
    setCsvData(outp);
  };

  const dataForCsv = csvData.map((transaction) => ({
    trackingId: `CDB${transaction.number}`,
    organisation: transaction.company.name,
    transactionType: transaction.type,
    title: transaction.title,
    contractingParty: transaction.contracting_party,
    status: transaction.state,
    feesInKind: transaction.fees_in_kind,
    feesCash: transaction.fees_cash,
    feesTotal: transaction.fees_total,
    dateOfAgreement: dayjs(transaction.agreement_date).format("DD-MM-YYYY"),
    createdDate: dayjs(transaction.commencement_date).format("DD-MM-YYYY"),
    submittedDate: dayjs(transaction.submission_date).format("DD-MM-YYYY"),
  }));

  //import data process
  const showImportModal = () => {
    setImportModalVisible(true);
  };

  //build company arrays
  useEffect(() => {
    axios.get(`/company`).then((res) => {
      let outp = [];

      // only clubs that are currently in Premier League
      const clubsInPremierLeague = res.data.filter(
        (company: any) =>
          clubArray.find((club) => club.value === company._id)?.inPremierLeague
      );

      for (let i = 0; i < clubsInPremierLeague.length; i++) {
        let item = {
          text: clubsInPremierLeague[i].name,
          value: clubsInPremierLeague[i]._id,
          display: clubsInPremierLeague[i].name,
          logo: clubsInPremierLeague[i].logo,
          key: i,
        };
        outp.push(item);
      }
      setCompanies(outp);
    });
  }, []);

  //update filter
  const updateFilter = (newFilterObj: Filter) => {
    fetchData(
      {
        ...pagination,
        current: 0,
      },
      newFilterObj,
      sort
    );
    handleFilterChange(newFilterObj);
  };

  //clear filter
  const clearFilter = () => {
    handlePageChange(0, pagination.pageSize);
    handleFilterChange({});
    handleTableChange(pagination, {}, sort, null, true);
  };

  //fetch data
  useEffect(() => {
    fetchData(pagination, filter);
  }, []);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Filter,
    sorter: { field?: keyof Filter; order?: number },
    extra: any,
    clearing: any
  ) => {
    if (clearing) {
      fetchData(pagination, filters, sorter);
    } else {
      fetchData(pagination, filter, sorter);
    }
  };

  const handleFilterChange = (filters: Filter) => {
    //set current state
    setFilter(filters);
    //store in cache
    localStorage.setItem("transactionFilter", JSON.stringify(filters));
  };

  useEffect(() => {
    fetchCsvData();
  }, [filter]);

  const handlePageChange = (page: any, pageSize: any) => {
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    //store in cache
    localStorage.setItem("transactionPage", page);
    localStorage.setItem("transactionPageSize", pageSize);
    //set current state
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  return (
    <>
      <DraftTransactionModal
        companies={companies}
        newDraftType={newDraftType}
        isModalVisible={termModalVisible}
        setIsModalVisible={setTermModalVisible}
      />
      <ImportDataModal
        isModalVisible={importModalVisible}
        setIsModalVisible={setImportModalVisible}
      />
      <SPageHeader
        title="Transactions"
        breadcrumb={routes}
        extra={[
          <Button
            key={0}
            onClick={() => fetchData(pagination, filter, sort)}
            icon={<SyncOutlined />}
          />,
          (isUserAdmin || isUserClub) && (
            <CSVLink
              key={1}
              headers={headers}
              data={dataForCsv}
              filename={`Transactions-Report-${dayjs(new Date()).format(
                "DD-MM-YYYY LT"
              )}`}
            >
              <Button icon={<ExportOutlined />}>Export</Button>
            </CSVLink>
          ),
          (isUserAdmin || isUserClub) && (
            <Button
              key={2}
              onClick={() => showImportModal()}
              icon={<UploadOutlined />}
            >
              Import
            </Button>
          ),
          (isUserAdmin || isUserClub) && (
            <Dropdown
              key={3}
              menu={{
                // it complains that the key (string) is not assignable to TransactionType
                // @ts-ignore
                onClick: handleCreateNewMenuClick,
                items: [
                  {
                    key: TransactionType.databank,
                    icon: <InsertRowBelowOutlined />,
                    label: "Databank Transaction",
                  },
                  {
                    key: TransactionType.threshold,
                    icon: <InsertRowAboveOutlined />,
                    label: "Threshold Transaction",
                  },
                  {
                    key: TransactionType.associated,
                    icon: <UsergroupAddOutlined />,
                    label: "Associated Party Transaction",
                  },
                ],
              }}
            >
              <Button key={4} type="primary">
                <PlusOutlined /> Create Draft <DownOutlined />
              </Button>
            </Dropdown>
          ),
        ]}
      />
      <SPageContent>
        <FilterCard
          filter={filter}
          setFilter={updateFilter}
          setSearchedText={setSearchedText}
          clearFilter={clearFilter}
        />
        <Card bodyStyle={{ paddingTop: "0" }} style={{ minWidth: "700px" }}>
          <TableToolbar title="" />
          {/*<Table columns={columns2} dataSource={data2} onChange={onChange} />*/}
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            scroll={{ x: 1300 }}
            //@ts-ignore
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`/transaction/${record._id}`);
                },
              };
            }}
            footer={() =>
              `${pageCount.startWindow}-${pageCount.endWindow} of  ${pageCount.total} total record(s)`
            }
          />
        </Card>
      </SPageContent>
    </>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const TransactionList = connect(mapStateToProps, null)(TransactionListStaging);

export default TransactionList;
