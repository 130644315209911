import React, { useState } from "react";
import axios from "axios";

import { Button, Form, message, Modal, Select, Typography } from "antd";
import TypeTag from "../TypeTag";
import StateTag from "../StateTag";
import { TransactionStatus, TransactionType } from "../../enums";

const { Paragraph } = Typography;
const { Option } = Select;

const typeOptions = Object.keys(TransactionType);

const ConvertTermModal = ({
  termId,
  termNumber,
  termType,
  isModalVisible,
  setIsModalVisible,
}: {
  termId: string;
  termNumber: string;
  termType: string;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const confirm = (data: any) => {
    setLoading(true);

    const hide = message.loading("Converting..", 1.0);

    axios
      .patch(`/term/${termId}`, {
        state: "draft",
        type: data.type,
      })
      .then(() => {
        hide();
        setLoading(false);
        message.success("Converted", 2).then((r) => window.location.reload());
      });
  };
  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    confirm(values);
  };
  const handleOk = () => {
    form.validateFields().then(() => form.submit());
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    // @ts-ignore
    <Modal
      title="Convert Transaction"
      width={750}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Convert
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="newTerm"
        layout="vertical"
        onFinish={onFormFinish}
        scrollToFirstError
      >
        <Paragraph>
          You are about to convert the{" "}
          <TypeTag type={termType as TransactionType} />
          transaction {termNumber} to a different type.
        </Paragraph>

        <Paragraph>
          This will revert the transaction back to a state of{" "}
          <StateTag state={TransactionStatus.draft} />
          and require resubmission by the club.
        </Paragraph>

        <Form.Item
          name="type"
          tooltip="Select a type to convert this transaction to."
          label="Target type"
          rules={[{ required: true }]}
        >
          <Select showSearch placeholder="Search to Select">
            {typeOptions
              .filter((opt) => opt !== termType)
              .map((opt) => (
                <Option key={1} value={opt}>
                  <TypeTag type={opt as TransactionType} />
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConvertTermModal;
