import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

import {
  Button,
  Card,
  Form,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  RollbackOutlined,
  SaveOutlined,
  SendOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import PartnershipForm from "../../components/PartnershipForm";
import StateTag from "../../components/StateTag";
import TypeTag from "../../components/TypeTag";
import styled from "styled-components";
import ConvertTermModal from "../../components/modals/ConvertTermModal";
import {
  AttachmentFile,
  Auth,
  BespokeAttachments,
  Company,
  Form as FormType,
} from "../../types";
import {
  AttachmentType,
  TransactionStatus,
  TransactionType,
  UserRole,
} from "../../enums";
import { capitalizeFirstLetter } from "../../utils";

const { Paragraph } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
`;

const formFields = [
  "title",
  "description",
  "agreement_date",
  "contracting_party",
  "contracting_party_jurisdiction",
  "parent_contracting_party",
  "brand",
  "brand_sector",
  "subject_matter",
  "associated_party",
  "arms_length",
  "summary",
  "commencement_date",
  "term",
  "renewal_provisions",
  "strategic_rationale",
  "fees_total",
  "fees_cash",
  "fees_in_kind",
  "fees_summary",
  "commercial_incentives",
  "fees_royalties",
  "associated_fees",
  "partner_designations",
  "exclusive_categories",
  "non_exclusive_categories",
  "territories",
  "assets_provided",
  "services_provided",
  "partner_rights",
  "licensing_rights",
  "other_rights",
  "competitive_process",
  "competing_bidders",
  "competing_bids",
  "transaction_nature",
  "comparable_deals_value",
  "source_of_relationship",
  "compliance_checks",
  "associated_party_relationship",
  "associated_party_fmv",
];

const draftMandatories = [
  "title",
  "description",
  "agreement_date",
  "contracting_party",
  "contracting_party_jurisdiction",
  "brand",
  "brand_sector",
  "subject_matter",
  "associated_party",
  "arms_length",
  "summary",
  "commencement_date",
  "term",
  "renewal_provisions",
  "strategic_rationale",
  "fees_total",
  "fees_cash",
  "fees_in_kind",
  "commercial_incentives",
  "fees_royalties",
  "associated_fees",
  "partner_designations",
  "exclusive_categories",
  "non_exclusive_categories",
  "territories",
  "assets_provided",
  "services_provided",
  "partner_rights",
];

const draftMandatoriesThreshold = [
  "competitive_process",
  "competing_bidders",
  "competing_bids",
  "transaction_nature",
  "comparable_deals_value",
  "source_of_relationship",
  "compliance_checks",
];

const draftMandatoriesAssociated = [
  "associated_party_relationship",
  "associated_party_fmv",
];

const FormPageStaging = ({ auth }: { auth: Auth }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [company, setCompany] = useState({} as Company);
  const [number, setNumber] = useState("CDB0000000");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [state, setState] = useState("");
  const [type, setType] = useState("");
  const [descriptionCreatedDate, setDescriptionCreatedDate] = useState("");
  const [descriptionSubmissionDate, setDescriptionSubmissionDate] =
    useState("");
  const [descriptionTotalFees, setDescriptionTotalFees] = useState(0);
  const [descriptionClub, setDescriptionClub] = useState("");
  const [descriptionSubmittedBy, setDescriptionSubmittedBy] = useState("");
  const [formLoading, setFormLoading] = useState(true);
  const [bespokeAttachments, setBespokeAttachments] =
    useState<BespokeAttachments>({
      unredacted_agreement: [],
      redacted_agreement: [],
      pl_reports: [],
    });
  const [attachmentList, setAttachmentList] = useState<AttachmentFile[]>([]);
  const [convertModalVisible, setConvertModalVisible] = useState(false);
  const [isExportingPdf, setIsExportingPdf] = useState(false);

  const params = useParams();

  const id = params.id;
  const isThreshold = type === "threshold";
  const isAssociatedParty = type === "associated";

  const isUserAdmin = auth.user.role === UserRole.admin;
  const isUserLegal = auth.user.role === UserRole.legal;
  const isUserClub = auth.user.role === UserRole.club;

  const canWrite =
    isUserAdmin ||
    (state !== "historic" &&
      state !== "submitted" &&
      ((type === "databank" &&
        (isUserAdmin || isUserLegal || (isUserClub && state === "draft"))) ||
        (type === "threshold" &&
          (isUserAdmin ||
            (isUserLegal && state !== "draft") ||
            (isUserClub && state === "draft"))) ||
        (type === "associated" &&
          (isUserAdmin || isUserLegal || (isUserClub && state === "draft")))));

  const routes = {
    items: [
      { title: "Transactions", path: "" },
      { title: number, path: "" },
    ],
  };

  //get form data
  const fetchFormData = () => {
    setFormLoading(true);
    const hide = message.loading("Loading..");
    axios
      .get(`/term/${id}`)
      .then((res) => {
        hide();
        let formData = {
          ...res.data,
          agreement_date: dayjs(res.data.agreement_date),
          commencement_date: dayjs(res.data.commencement_date),
          submission_date: dayjs(res.data.submission_date),
        };

        setCompany({
          ...company,
          id: res.data.company,
        });
        if (res.data.submission_date)
          setDescriptionCreatedDate(
            dayjs(res.data.submission_date).format("Do MMM, YYYY")
          );
        if (res.data.submitted_to_pl_date)
          setDescriptionSubmissionDate(
            dayjs(res.data.submitted_to_pl_date).format("Do MMM, YYYY")
          );
        if (res.data.fees_total) setDescriptionTotalFees(res.data.fees_total);
        if (res.data.created_by)
          setDescriptionSubmittedBy(res.data.created_by.email);

        // here we set the values for the partnership form
        form.setFieldsValue(formData);

        if (res.data.number) setNumber(`CDB${res.data.number}`);
        if (res.data.title) setTitle(res.data.title);
        if (res.data.description) setDescription(res.data.description);
        if (res.data.state) setState(res.data.state);
        if (res.data.type) setType(res.data.type);

        setFormLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (err.response.status === 401) {
            navigate("/403");
            return;
          }
          navigate("/404");
        }
      });
  };
  //init form data
  useEffect(() => {
    fetchFormData();
  }, []);
  //get company details for header
  useEffect(() => {
    if (company.id) {
      axios.get(`/company/${company.id}`).then((res: { data: Company }) => {
        if (res.data) {
          setCompany({
            ...company,
            logo: res.data.logo,
            name: res.data.name,
          });
          setDescriptionClub(res.data.name);
        }
      });
    }
  }, [company.id]);
  //fetch attachments
  useEffect(() => {
    if (id) {
      axios.get(`/upload?record=${id}`).then((res) => {
        if (res.data) {
          let generalOutp: any = [];
          let redactedAtt: any = [];
          let unredactedAtt: any = [];
          let plReportAtt: any = [];
          res.data.forEach((row: any) => {
            let att = {
              uid: row._id,
              name: row.file_name,
              status: "done",
              response: "200",
              url: row.url,
            };
            if (row.type && row.type === AttachmentType.redacted_agreement)
              redactedAtt.push(att);
            else if (
              row.type &&
              row.type === AttachmentType.unredacted_agreement
            )
              unredactedAtt.push(att);
            else if (row.type && row.type === AttachmentType.pl_reports)
              plReportAtt.push(att);
            else generalOutp.push(att);
          });
          setAttachmentList(generalOutp);
          setBespokeAttachments({
            unredacted_agreement: unredactedAtt,
            redacted_agreement: redactedAtt,
            pl_reports: plReportAtt,
          });
        }
      });
    }
  }, []);

  //save data
  const saveData = (data: any) => {
    setFormLoading(true);

    const hide = message.loading("Saving...", 1.0);

    axios.patch(`/term/${id}`, data).then(() => {
      hide();
      setFormLoading(false);
      message.success("Saved", 2.5);
      fetchFormData();
    });
  };

  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    saveData(values);
  };
  const updateState = (targetState: any) => {
    saveData({
      state: targetState,
    });
  };

  const save = () => {
    let output = {} as FormType;

    for (let i = 0; i < formFields.length; i++) {
      output[formFields[i] as keyof FormType] = form.getFieldValue(
        formFields[i]
      );
    }

    saveData(output);
  };

  const deleteRecord = () => {
    const hide = message.loading("Deleting record..", 1.0);
    axios.delete(`/term/${id}`).then(() => {
      hide();
      setFormLoading(false);
      message.error("Transaction deleted", 2.5);
      navigate("/transaction");
    });
  };

  const saveAndSubmit = () => {
    let i;
    let output = {} as FormType;
    let formComplete = true;

    //build values output
    for (let i = 0; i < formFields.length; i++) {
      output[formFields[i] as keyof FormType] = form.getFieldValue(
        formFields[i]
      );
    }

    //mandatory fields for draft
    for (i = 0; i < draftMandatories.length; i++) {
      const value = form.getFieldValue(draftMandatories[i]);

      if (value !== 0 && (!value || value.length === 0)) {
        formComplete = false;
      }
    }

    //additional mandatories for threshold
    if (isThreshold) {
      //field mandatories
      for (i = 0; i < draftMandatoriesThreshold.length; i++) {
        const value = form.getFieldValue(draftMandatoriesThreshold[i]);

        if (value !== 0 && (!value || value.length === 0)) {
          formComplete = false;
        }
      }

      if (attachmentList.length === 0) {
        message.error(
          "At least one attachment is required to submit a Threshold transaction.",
          3.0
        );
        return;
      }
    }

    //additional mandatories for threshold
    if (isAssociatedParty) {
      //field mandatories
      for (i = 0; i < draftMandatoriesAssociated.length; i++) {
        const value = form.getFieldValue(draftMandatoriesAssociated[i]);

        if (value !== 0 && (!value || value.length === 0)) {
          formComplete = false;
        }
      }

      if (
        bespokeAttachments.redacted_agreement.length === 0 ||
        bespokeAttachments.unredacted_agreement.length === 0
      ) {
        message.error(
          "Redacted and unredacted agreement attachments are required to submit an Associated Party transaction.",
          3.0
        );
        return;
      }
    }

    if (!formComplete) {
      message.error("Form incomplete.", 3.0);
    } else {
      form.validateFields().then(() => {
        output.state = TransactionStatus.pending;
        saveData(output);
      });
    }
  };

  const onExportPdf = async () => {
    setIsExportingPdf(true);
    try {
      const payload = {
        id: number,
        template: "pl_commercial_data_bank",
        isPublic: true,
        params: {
          number: "CDB1000987",
          title: form.getFieldValue("title"),
          type: capitalizeFirstLetter(type),
          description: form.getFieldValue("description") || "-",
          agreementDate:
            form.getFieldValue("agreement_date").format("dddd, MMMM Do YYYY") ||
            "-",
          submittedBy: form.getFieldValue(descriptionSubmittedBy) || "-",
          brand: form.getFieldValue("brand") || "-",
          territories: form.getFieldValue("territories"),
          parentCompany: form.getFieldValue("parent_contracting_party") || "-",
          brandSector: form.getFieldValue("brand_sector") || "-",
          subjectMatter: form.getFieldValue("subject_matter") || "-",
          associatedParty: form.getFieldValue("associated_party") || "-",
          armsLength: form.getFieldValue("arms_length") || "-",
          summary: form.getFieldValue("summary") || "-",
          term: form.getFieldValue("term") || "-",
          renewalProvisions: form.getFieldValue("renewal_provisions") || "-",
          strategicRationale: form.getFieldValue("strategic_rationale") || "-",
          feesCash: form.getFieldValue("fees_cash") || "-",
          feesInKind: form.getFieldValue("fees_in_kind") || "-",
          feesTotal: form.getFieldValue("fees_total") || "-",
          feesSummary: form.getFieldValue(descriptionTotalFees) || "-",
          feesRoyalties: form.getFieldValue("fees_royalties") || "-",
          commercialIncentives:
            form.getFieldValue("commercial_incentives") || "-",
          associatedFees: form.getFieldValue("associated_fees") || "-",
          partnerDesignations:
            form.getFieldValue("partner_designations") || "-",
          exclusiveCategories:
            form.getFieldValue("exclusive_categories") || "-",
          nonExclusiveCategories:
            form.getFieldValue("non_exclusive_categories") || "-",
          assetsProvided: form.getFieldValue("assets_provided") || "-",
          servicesProvided: form.getFieldValue("services_provided") || "-",
          partnerRights: form.getFieldValue("partner_rights") || "-",
          licensingRights: form.getFieldValue("licensing_rights") || "-",
          otherRights: form.getFieldValue("other_rights") || "-",
          competitiveProcess: form.getFieldValue("competitive_process") || "-",
          competingBidders: form.getFieldValue("competing_bidders") || "-",
          competingBids: form.getFieldValue("competing_bids") || "-",
          transactionNature: form.getFieldValue("transaction_nature") || "-",
          comparableDealsValue:
            form.getFieldValue("comparable_deals_value") || "-",
          sourceOfRelationship:
            form.getFieldValue("source_of_relationship") || "-",
          complianceChecks: form.getFieldValue("compliance_checks") || "-",
        },
      };

      const response = await axios.post(`/export/pdf`, payload, {
        responseType: "arraybuffer",
      });

      const file = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `${number} Transaction Report.pdf`;
      link.click();
    } catch (err) {
      console.log(err);
    }
    setIsExportingPdf(false);
  };

  return (
    <>
      <ConvertTermModal
        termId={id}
        termNumber={number}
        termType={type}
        isModalVisible={convertModalVisible}
        setIsModalVisible={setConvertModalVisible}
      />
      <SPageHeader
        title={title}
        breadcrumb={routes}
        tags={[
          state && (
            <StateTag
              key={0}
              state={TransactionStatus[state as TransactionStatus]}
            />
          ),
          type && (
            <TypeTag key={1} type={TransactionType[type as TransactionType]} />
          ),
        ]}
        avatar={company.logo && { src: `/club_logos/48/${company.logo}` }}
        extra={[
          isUserAdmin && (
            <Popconfirm
              title="Are you sure you would like to delete this record?"
              key="delete-record"
              icon={<DeleteOutlined />}
              onConfirm={() => deleteRecord()}
            >
              <Button
                danger={true}
                loading={formLoading}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          ),
          isUserAdmin && (
            <Button
              key="open-convert-modal"
              loading={formLoading}
              onClick={() => setConvertModalVisible(true)}
              icon={<SwapOutlined />}
            />
          ),
          state !== "draft" && canWrite && (isUserAdmin || isUserLegal) && (
            <Popconfirm
              title="Send this transaction back to draft?"
              key="back-to-draft"
              icon={<CloseCircleOutlined />}
              onConfirm={() => updateState("draft")}
              style={{ color: "red" }}
            >
              <Button loading={formLoading} icon={<RollbackOutlined />}>
                Draft
              </Button>
            </Popconfirm>
          ),
          state === "pending" && canWrite && (isUserAdmin || isUserLegal) && (
            <Popconfirm
              title="Confirm reject"
              key="confirm-reject"
              icon={<CloseCircleOutlined />}
              onConfirm={() => updateState("rejected")}
              style={{ color: "red" }}
            >
              <Button loading={formLoading} icon={<CloseOutlined />}>
                Reject
              </Button>
            </Popconfirm>
          ),
          state === "pending" && canWrite && (isUserAdmin || isUserLegal) && (
            <Popconfirm
              title="Confirm approve"
              key="confirm-approve"
              icon={<CheckCircleOutlined />}
              onConfirm={() => updateState("approved")}
              style={{ color: "green" }}
            >
              <Button loading={formLoading} icon={<CheckOutlined />}>
                Approve
              </Button>
            </Popconfirm>
          ),
          <Button
            key="export-pdf"
            loading={isExportingPdf}
            onClick={onExportPdf}
            icon={<FilePdfOutlined />}
          >
            Export PDF
          </Button>,
          <Button
            loading={formLoading}
            type="primary"
            disabled={!canWrite}
            key="save"
            onClick={() => save()}
            icon={<SaveOutlined />}
          >
            Save
          </Button>,
          state === "draft" && (
            <Popconfirm
              title="Submit for review?"
              key="submit"
              icon={<CheckCircleOutlined />}
              onConfirm={() => saveAndSubmit()}
            >
              <Button loading={formLoading} icon={<SendOutlined />}>
                Submit
              </Button>
            </Popconfirm>
          ),
        ]}
      >
        <Row>
          <div style={{ flex: 1 }}>
            <Paragraph>{description}</Paragraph>
          </div>

          <Space size="large">
            {descriptionCreatedDate && (
              <Statistic
                title="Created"
                value={descriptionCreatedDate}
                valueStyle={{ fontSize: "large" }}
              />
            )}
            {descriptionSubmissionDate && (
              <Statistic
                title="Submitted"
                value={descriptionSubmissionDate}
                valueStyle={{ fontSize: "large" }}
              />
            )}
            {descriptionTotalFees !== 0 && (
              <Statistic
                title="Total fees"
                prefix="£"
                value={descriptionTotalFees}
                valueStyle={{ fontSize: "large" }}
              />
            )}

            <Statistic
              title="Club"
              value={descriptionClub}
              valueStyle={{ fontSize: "large" }}
            />

            <Statistic
              title="Created by"
              value={descriptionSubmittedBy}
              valueStyle={{ fontSize: "large" }}
            />
          </Space>
        </Row>
      </SPageHeader>
      <SPageContent>
        <Card>
          <PartnershipForm
            {...formItemLayout}
            form={form}
            attachmentList={attachmentList}
            setAttachmentList={setAttachmentList}
            bespokeAttachments={bespokeAttachments}
            setBespokeAttachments={setBespokeAttachments}
            onFinish={onFormFinish}
            newRecord={false}
            canWrite={canWrite}
            userRole={auth.user.role}
            isThreshold={isThreshold}
            isAssociatedParty={isAssociatedParty}
            id={id}
            scrollToFirstError
          />
        </Card>
      </SPageContent>
    </>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const FormPage = connect(mapStateToProps, null)(FormPageStaging);

export default FormPage;
