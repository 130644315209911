import React from "react";
import { Tag } from "antd";
import { TransactionType } from "../enums";

const TypeTag = ({ type }: { type: TransactionType }) => {
  const getDisplay = () => {
    switch (type) {
      case TransactionType.threshold:
        return "Threshold";
      case TransactionType.databank:
        return "Databank";
      case TransactionType.associated:
        return "Assoc. Party";
    }
  };

  const typeEnum = Object.fromEntries(
    Object.keys(TransactionType).map((type) => [
      [type],
      {
        color: "pink",
        display: getDisplay(),
      },
    ])
  );

  const typeResult = typeEnum[type] || {
    color: "blue",
    display: "n/a",
  };

  return <Tag color={typeResult.color}>{typeResult.display}</Tag>;
};

export default TypeTag;
