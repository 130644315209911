import React, { ReactNode, useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";

const { Sider } = Layout;

const SSider = styled(Sider)`
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  z-index: 20;
`;

const SLogo = styled.div`
  display: flex;
  margin: 12px;
  justify-content: center;

  img {
    width: ${({ collapsed }: { collapsed: boolean }) =>
      collapsed ? "52px" : "128px"};
    height: auto;
  }
`;

const routes = [
  //  TODO uncomment when they want to test dashboard
  // {
  //   path: "dashboard",
  //   name: "Dashboard",
  //   icon: <DashboardOutlined />,
  // },
  {
    path: "transaction",
    name: "Transactions",
    icon: <FileOutlined />,
  },
  {
    path: "user",
    name: "Users",
    icon: <UserOutlined />,
  },
];

type Route = {
  path: string;
  name: string;
  icon: ReactNode;
};

const createLinks = (routes: Route[]) => {
  return routes.map((prop: Route, key: number) => {
    return {
      key: key.toString(),
      icon: prop.icon,
      label: prop.name,
    };
  });
};

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState(["0"]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    for (var i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) > -1) {
        setActiveKey([i.toString()]);
        return;
      }
    }
  }, [location.pathname]);

  const clickMenu = (menu: any) => {
    setActiveKey(menu.key);
    navigate(`/${routes[menu.key].path}`);
  };

  return (
    <SSider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <SLogo id="logo" collapsed={collapsed}>
        {collapsed ? (
          <img src="/premierleague_small.png" alt="Premier League Logo" />
        ) : (
          <img src="/premierleague.png" alt="Premier League Logo" />
        )}
      </SLogo>
      <Menu
        defaultSelectedKeys={activeKey}
        selectedKeys={activeKey}
        onClick={clickMenu}
        mode="inline"
        items={createLinks(routes)}
      />
    </SSider>
  );
};

export default Sidebar;
