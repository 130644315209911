import React, { useState } from "react";

import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import styled from "styled-components";
import axios from "axios";
import { connect } from "react-redux";
import { Auth, Company } from "../../types";
import { UserRole } from "../../enums";

require("dotenv").config();

const { Paragraph } = Typography;
const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const InviteUserModalStaging = (props: any) => {
  const { companies, isModalVisible, setIsModalVisible } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    form.validateFields().then((r) => form.submit());
  };

  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    saveData(values);
  };

  const saveData = (data: any) => {
    setLoading(true);
    const hide = message.loading("Saving..", 1.0);

    let input = { ...data };

    axios
      .post(`/auth/invite`, input)
      .then((res) => {
        form.resetFields();
        hide();
        setLoading(false);
        message.success("User Invited", 2.5);
        setIsModalVisible(false);
      })
      .catch((e) => {
        hide();
        setLoading(false);
        message.error("Error with inviting user", 2.5);
        setIsModalVisible(false);
      });
  };

  return (
    <Modal
      title={`Invite User`}
      width={750}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Invite
        </Button>,
      ]}
    >
      <Paragraph>
        To invite a new user to the Commercial Databank, please provide the
        following details and submit. The user will receive a notification
        containing a link within 30 minutes of the invite.
      </Paragraph>
      <Form
        form={form}
        name="newTerm"
        layout="vertical"
        onFinish={onFormFinish}
        scrollToFirstError
      >
        <Form.Item name="company" label="Company" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="display"
            filterOption={(input, option) =>
              option.display.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {companies.map((company: Company, index: number) => (
              <Option
                key={index}
                value={company.value}
                display={company.display}
              >
                <SOptionLogoWrapper>
                  <Avatar
                    size="small"
                    src={`/club_logos/48/${company.logo}`}
                    alt="avatar"
                  />
                  {company.display}
                </SOptionLogoWrapper>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Role" rules={[{ required: true }]}>
          <Select>
            <Option key={1} value={UserRole.admin}>
              Admin
            </Option>
            <Option key={2} value={UserRole.legal}>
              Legal Team
            </Option>
            <Option key={3} value={UserRole.club}>
              Club
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const InviteUserModal = connect(mapStateToProps, null)(InviteUserModalStaging);

export default InviteUserModal;
