import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import { ClearOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import styled from "styled-components";
import BrandSectorOptions from "./enums/BrandSectorOptions";
import getTerritoryInputOptions from "./enums/getTerritoryInputOptions";
import getSubjectMatterOptions from "./enums/getSubjectMatterOptions";
import getClubInputOptions from "./enums/getClubInputOptions";
import fieldToJSX from "./enums/fieldToJSX";
import { Auth, FilterField, FullFilterForm } from "../types";
import { TransactionType, UserRole } from "../enums";
import { getLabelForTransactionType } from "../utils";

const { Search } = Input;
const { Option } = Select;

const S_BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const S_FilterText = styled.span``;

const S_FilterSearchBox = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
`;

const S_FilterForm = styled(Form)`
  margin-top: 24px;
  .ant-form-item-label > label {
    font-size: 12px;
  }
`;

const filterFields: FilterField[] = [
  {
    name: "title",
    label: "Title",
    type: "text",
  },
  {
    name: "number",
    label: "Tracking ID",
    type: "number",
    number: {
      formatter: (value: number) =>
        `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      parser: (value: string) => value.replace(/£\s?|(,*)/g, ""),
    },
  },
  {
    name: "state",
    label: "Status",
    type: "select",
    select: {
      mode: "multiple",
      options: (
        <>
          <Option value="historic">Historical</Option>
          <Option value="draft">Draft</Option>
          <Option value="pending">Pending</Option>
          <Option value="rejected">Rejected</Option>
          <Option value="approved">Approved</Option>
          <Option value="submitted">Submitted</Option>
        </>
      ),
    },
  },
  {
    name: "type",
    label: "Type",
    type: "select",
    select: {
      mode: "multiple",

      options: (
        <>
          {Object.keys(TransactionType).map((transactionType, index) => {
            return (
              <Option key={index} value={transactionType}>
                {getLabelForTransactionType(transactionType)}
              </Option>
            );
          })}
        </>
      ),
    },
  },
  {
    name: "submission_date",
    label: "Created",
    type: "daterange",
  },
  {
    name: "submitted_to_pl_date",
    label: "Submitted",
    type: "daterange",
  },
  {
    name: "agreement_date",
    label: "Execution date",
    type: "daterange",
  },
  {
    name: "commencement_date",
    label: "Commencement date",
    type: "daterange",
  },
  {
    name: "brand",
    label: "Brand",
    type: "text",
  },
  {
    name: "brand_sector",
    label: "Brand sector",
    type: "cascader",
    cascader: {
      mode: "multiple",
      options: BrandSectorOptions,
    },
  },
  {
    name: "subject_matter",
    label: "Subject matter",
    type: "select",
    select: {
      mode: "multiple",
      options: getSubjectMatterOptions(),
    },
  },
  {
    name: "territories",
    label: "Territories",
    type: "select",
    select: {
      mode: "multiple",
      options: getTerritoryInputOptions(),
    },
  },
  {
    name: "associated_party",
    label: "Assoc. party",
    type: "select",
    select: {
      options: (
        <>
          <Option value="a">Yes</Option>
          <Option value="b">No</Option>
        </>
      ),
    },
  },
  {
    name: "arms_length",
    label: "Arm's length",
    type: "select",
    select: {
      options: (
        <>
          <Option value="a">Yes</Option>
          <Option value="b">No</Option>
        </>
      ),
    },
  },
];

const adminFilterFields: FilterField[] = [
  {
    name: "company",
    label: "Club",
    type: "select",
    select: {
      mode: "multiple",
      options: getClubInputOptions(),
    },
  },
];

const emptyFormState = {} as FullFilterForm;

[...filterFields, ...adminFilterFields].forEach((field: FilterField) => {
  // it will complain that you cannot assign undefined
  // to "state" and "type" because they are enums
  // @ts-ignore
  emptyFormState[field.name] = undefined;
});

const FilterCardStaging = ({
  filter,
  setFilter,
  clearFilter,
  setSearchedText,
  auth,
}: {
  filter: FullFilterForm;
  setFilter: (filter: FullFilterForm) => void;
  clearFilter: () => void;
  setSearchedText: (value: string) => void;
  auth: Auth;
}) => {
  const [form] = Form.useForm();
  const [expanded, setExpanded] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>(
    (filter && filter.text) || ""
  );

  const [searchUserFriendly, setSearchUserFriendly] = useState("");

  let inpArr = filterFields;
  if (auth.user.role === UserRole.admin) {
    inpArr = inpArr.concat(adminFilterFields);
  }

  const onSearchText = (value: string) => {
    setSearchedText(value);
    setSearchInputValue(value);

    handleSearchUserFriendly(value);

    setFilter({
      ...filter,
      text: value,
    });
  };

  const onClearFilter = () => {
    setSearchInputValue("");
    form.setFieldsValue(emptyFormState);
    clearFilter();
  };

  const handleSearchUserFriendly = (filter: any) => {
    let searchTerms;
    if (filter.match(/"/g)) {
      searchTerms = filter.split(/ (?=([^\"]*\"[^\"]*\")*[^\"]*$)/g);
    } else {
      searchTerms = filter.split(" ");
    }

    let uniqueSearch = [...new Set(searchTerms)];

    setSearchUserFriendly(
      uniqueSearch.map((us) => `<b>${us}</b>`).join(" <u>OR</u> ")
    );
  };

  //if filter is present on load, have the card expanded by default
  useEffect(() => {
    if (filter && Object.keys(filter).length > 0) {
      if (filter.text) handleSearchUserFriendly(filter.text);

      form.setFieldsValue(filter);
      setExpanded(true);

      if (filter.title) {
        setSearchedText(filter.title);
      }
    }
  }, []);

  const getFields = () => {
    const children = [];
    for (let i = 0; i < inpArr.length; i++) {
      children.push(
        <Col md={8} sm={12} xs={24} key={i}>
          <Form.Item name={inpArr[i].name as string} label={inpArr[i].label}>
            {fieldToJSX(inpArr[i], true)}
          </Form.Item>
        </Col>
      );
    }
    return children;
  };

  const onApply = () => {
    let outp = { ...filter };
    for (let i = 0; i < inpArr.length; i++) {
      let value = form.getFieldValue(inpArr[i].name as string);

      // check if the filter value is empty string or empty array (for the 'type' filter)
      // and if it is, set it as undefined so the form updates correctly
      if (!value || value.length === 0) {
        value = undefined;
      }

      // TS will complain that you cannot assign a string valuex
      // to "state" and "type" because they are enums
      // @ts-ignore
      outp[inpArr[i].name] = value;
    }
    if (outp.text) handleSearchUserFriendly(outp.text);
    setFilter(outp);
  };

  return (
    <Card>
      <S_BodyWrapper>
        <S_FilterSearchBox>
          <Search
            placeholder="Text search"
            allowClear
            enterButton="Search"
            defaultValue={searchInputValue}
            onSearch={onSearchText}
          />

          <Space>
            <Button type="link" onClick={() => setExpanded(!expanded)}>
              Filters
              {expanded ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </Space>
        </S_FilterSearchBox>
        {searchUserFriendly && (
          <S_FilterText>
            Searching for:{" "}
            <span
              dangerouslySetInnerHTML={{ __html: searchUserFriendly }}
            ></span>
          </S_FilterText>
        )}
      </S_BodyWrapper>
      {expanded && (
        <S_FilterForm
          layout="horizontal"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          form={form}
          onFinish={onApply}
        >
          <Row gutter={24}>{getFields()}</Row>

          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space>
                {filter && Object.keys(filter).length > 0 && (
                  <Button
                    key={1}
                    onClick={onClearFilter}
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                )}
                <Button type="primary" htmlType="submit">
                  Apply
                </Button>
              </Space>
            </Col>
          </Row>
        </S_FilterForm>
      )}
    </Card>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const FilterCard = connect(mapStateToProps, null)(FilterCardStaging);

export default FilterCard;
