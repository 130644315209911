import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Countdown from "react-countdown";

import { Button, message, Modal, Typography } from "antd";

import { refreshUserToken, logoutUser } from "../../actions";
import store from "../../utils/store";

const { Paragraph } = Typography;

const countdownRender = ({ minutes, seconds }: any) =>
  minutes > 0 ? (
    <span>
      {minutes} minutes, {seconds} seconds
    </span>
  ) : (
    <span>{seconds} seconds</span>
  );

const JwTExpiryModal = () => {
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [expiresDate, setExpiresDate] = useState<Date>();
  const [isExtendLoading, setIsExtendLoading] = useState<boolean>(false);

  let modalIsOpen = false;

  const refreshAccessToken = () => {
    setIsExtendLoading(true);
    // @ts-ignore
    store.dispatch(refreshUserToken());
    message.success("Access extended", 3.0);
    setIsExtendLoading(false);
    setModalActive(false);
  };

  const logoutUserClick = async () => {
    // @ts-ignore
    await store.dispatch(logoutUser());
    window.location.href = "/logout";
  };

  const checkTokenExpiry = () => {
    const token = localStorage.cdToken;
    const decoded = jwt_decode(token) as { exp: number };

    const currentTime = Date.now() / 1000;

    const secondsUntilExpire = decoded.exp - currentTime;
    // @ts-ignore
    var date = new Date(decoded.exp * 1000);

    setExpiresDate(date);

    if (secondsUntilExpire < 0) {
      //token already expired, log user out
      logoutUserClick();
    } else if (!modalActive && secondsUntilExpire < 300) {
      //due to expire in the next 5 mins
      setModalActive(true);
    } else if (modalIsOpen) {
      //token has been extended succesfully but modal is still open
      setIsExtendLoading(false);
      setModalActive(false);
    }
  };

  useEffect(() => {
    if (localStorage.cdToken) {
      //every 5 seconds, check if expiry due
      const interval = setInterval(() => {
        checkTokenExpiry();
      }, 5000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [localStorage.cdToken]);

  return (
    <Modal
      title={`Session Due to Expire`}
      width={500}
      open={modalActive}
      closable={false}
      footer={[
        <Button key="back" onClick={() => logoutUserClick()}>
          Logout Now
        </Button>,

        <Button
          loading={isExtendLoading}
          key="submit"
          type="primary"
          onClick={() => refreshAccessToken()}
        >
          Extend Access
        </Button>,
      ]}
    >
      <Paragraph>
        security purposes, your session is due to expire in: <br />
        <strong>
          <Countdown renderer={countdownRender} date={expiresDate} />.
        </strong>
        <br />
        <br />
        time you will be <b>logged out</b> of the application.
        <br />
        <br />
        Please click the Extend Access button below if you would like to
        continue using the Commercial Databank.
      </Paragraph>
    </Modal>
  );
};

export default JwTExpiryModal;
