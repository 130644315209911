import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized";
import NoFoundPage from "./pages/NoFoundPage";
import Logout from "./pages/Logout";
import Dashboard from "./pages/Dashboard";
import Transaction from "./pages/Transaction";
import User from "./pages/User";
import JwTExpiryModal from "./components/modals/JwTExpiryModal";
import { connect } from "react-redux";
import FormNew from "./pages/transaction/FormNew";
import Form from "./pages/transaction/Form";
import TransactionsList from "./pages/transaction/List";
import UsersList from "./pages/user/List";
import Overview from "./pages/dashboard/Overview";
import { Auth } from "./types";

const AppStaging = ({ auth }: { auth: Auth }) => {
  return (
    <Router>
      <JwTExpiryModal />
      <Routes>
        <Route path="/" element={<Login />} />
        {auth.isAuthenticated ? (
          <React.Fragment>
            {/*  TODO uncomment when they want to test dashboard */}
            {/*<Route path="dashboard" element={<Dashboard />}>*/}
            {/*  <Route path="" element={<Overview />} />*/}
            {/*</Route>*/}
            <Route path="transaction" element={<Transaction />}>
              <Route path="" element={<TransactionsList />} />
              <Route path="new" element={<FormNew />} />
              <Route path=":id" element={<Form />} />
            </Route>
            <Route path="user" element={<User />}>
              <Route path="" element={<UsersList />} />
            </Route>
            <Route path="*" element={<NoFoundPage />} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route path="logout" element={<Logout />} />
            <Route path="/403" element={<Unauthorized />} />
          </React.Fragment>
        )}
      </Routes>
    </Router>
  );
};
const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});
const App = connect(mapStateToProps)(AppStaging);

export default App;
