import { SET_CURRENT_USER } from "../actions";
import isEmpty from "is-empty";
import { ActionWithPayload } from "../types";

export type SetCurrentUserPayload = {
  company: string;
  companyLogo: string;
  email: string;
  id: string;
  role: string;
  iat: number;
  exp: number;
};

const initialState = {
  isAuthenticated: false,
  user: {},
};
export default function authReducer(
  state = initialState,
  action: ActionWithPayload<SetCurrentUserPayload>
) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
      return;
    default:
      return state;
  }
}
