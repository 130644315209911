export enum TransactionStatus {
  historic = "historic",
  draft = "draft",
  pending = "pending",
  rejected = "rejected",
  approved = "approved",
  submitted = "submitted",
}

export enum TransactionType {
  threshold = "threshold",
  databank = "databank",
  associated = "associated",
}

export enum UserRole {
  admin = "admin",
  club = "club",
  legal = "legal",
}

export enum AttachmentType {
  default = "default",
  redacted_agreement = "redacted_agreement",
  unredacted_agreement = "unredacted_agreement",
  pl_reports = "pl_reports",
}
