import React, { useEffect } from "react";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import styled from "styled-components";
import { connect } from "react-redux";
import { Spin } from "antd";
import { loginUser } from "../actions/";
import { useNavigate } from "react-router-dom";
import { Auth } from "../types";

const SLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoginStaging = ({
  auth,
  loginUser,
}: {
  auth: Auth;
  loginUser: ({ accessToken }: { accessToken: string }) => void;
}) => {
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        loginUser({
          accessToken: result.accessToken,
        });
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, loginUser]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
        };
        instance.acquireTokenSilent(accessTokenRequest).then((res) => {
          //login to CDB
          loginUser({
            accessToken: res.accessToken,
          });
        });
      } else {
        instance.loginRedirect();
      }
    }
  }, [instance, accounts, inProgress, loginUser]);

  useEffect(() => {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (auth.isAuthenticated) {
      navigate("/transaction");
    }
  }, [navigate, auth.user.role, auth.isAuthenticated]);

  return (
    <SLoginWrapper>
      <UnauthenticatedTemplate>
        <Spin size="large" />
      </UnauthenticatedTemplate>
    </SLoginWrapper>
  );
};

const mapStateToProps = (state: { auth: Auth; errors: any }) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = {
  loginUser,
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginStaging);

export default Login;
