import jwt_decode from "jwt-decode";
import axios from "axios";
import { Dispatch } from "redux";

export type DecodedToken = {
  company: string;
  companyLogo: string;
  email: string;
  id: string;
  role: string;
  iat: number;
  exp: number;
};

//user actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// login - get user token
export const loginUser =
  (userData: { accessToken: string }) => (dispatch: Dispatch) => {
    axios
      .post(`/auth/login`, userData)
      .then((res) => {
        if (res.data.success) {
          const { token } = res.data;
          localStorage.setItem("cdToken", token);
          setAuthToken(token);
          const decoded = jwt_decode(token);
          dispatch(setCurrentUser(decoded as DecodedToken));
        }
      })
      .catch((err) => console.log(err));
  };
//refresh token
export const refreshUserToken = () => (dispatch: Dispatch) => {
  axios
    .post(`/auth/refresh`)
    .then((res) => {
      if (res.data.success) {
        const { token } = res.data;
        localStorage.setItem("cdToken", token);
        setAuthToken(token);
        const decoded = jwt_decode(token) as DecodedToken;
        dispatch(setCurrentUser(decoded));
      }
    })
    .catch((err) => console.log(err));
};

// set logged in user
export const setCurrentUser = (payload: DecodedToken) => {
  return {
    type: SET_CURRENT_USER,
    payload: payload,
  };
};

// set auth token on login
export const setAuthToken = (token: any) => {
  if (token) {
    // apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // delete auth header
    axios.defaults.headers.common["Authorization"] = "";
  }
};
// log user out
export const logoutUser = () => (dispatch: any) => {
  localStorage.clear();
  sessionStorage.clear();
  setAuthToken(false);
  dispatch(setCurrentUser({} as DecodedToken));
};
