import React from "react";
import { Avatar } from "antd";

import styled from "styled-components";
import { Company } from "../types";

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const StateTag = ({ company }: { company: Company }) => {
  return (
    company && (
      <SOptionLogoWrapper>
        <Avatar
          shape="square"
          size="small"
          src={`/club_logos/48/${company.logo}`}
          alt="avatar"
        />
        {company.name}
      </SOptionLogoWrapper>
    )
  );
};

export default StateTag;
