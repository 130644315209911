import { Avatar, Select } from "antd";
import React from "react";
import styled from "styled-components";

const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

export let clubArray = [
  {
    value: "61aa5f9b8bb941134435403c",
    display: "Arsenal",
    logo: "ars.png",
    inPremierLeague: true,
  },
  {
    value: "62c80a5f72f8e270f2253ec1",
    display: "AFC Bournemouth",
    logo: "bou.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5ed08bb9411344354039",
    display: "Aston Villa",
    logo: "avl.png",
    inPremierLeague: true,
  },
  {
    value: "61aa61688bb9411344354074",
    display: "Brentford",
    logo: "bre.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5fc48bb941134435403f",
    display: "Brighton and Hove Albion",
    logo: "bri.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5fcd8bb9411344354042",
    display: "Burnley",
    logo: "bur.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5fd28bb9411344354045",
    display: "Chelsea",
    logo: "che.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5fd78bb9411344354048",
    display: "Crystal Palace",
    logo: "cry.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5fde8bb941134435404b",
    display: "Everton",
    logo: "eve.png",
    inPremierLeague: true,
  },
  {
    value: "62c80a5e72f8e270f2253ec0",
    display: "Fulham",
    logo: "ful.png",
    inPremierLeague: true,
  },
  {
    value: "61aa5fed8bb9411344354051",
    display: "Leeds United",
    logo: "lee.png",
    inPremierLeague: false,
  },
  {
    value: "61aa5ff58bb9411344354054",
    display: "Leicester City",
    logo: "lei.png",
    inPremierLeague: false,
  },
  {
    value: "64899bc402304b1d27da382b",
    display: "Luton Town",
    logo: "lut.png",
    inPremierLeague: true,
  },
  {
    value: "61a3de370d6ee97a5126bf57",
    display: "Liverpool FC",
    logo: "liv.png",
    inPremierLeague: true,
  },
  {
    value: "61aa600a8bb9411344354057",
    display: "Manchester City",
    logo: "mci.png",
    inPremierLeague: true,
  },
  {
    value: "61aa600f8bb941134435405a",
    display: "Manchester United",
    logo: "mun.png",
    inPremierLeague: true,
  },
  {
    value: "61aa60158bb941134435405d",
    display: "Newcastle United",
    logo: "new.png",
    inPremierLeague: true,
  },
  {
    value: "61aa61cf8bb941134435407a",
    display: "Norwich City",
    logo: "nor.png",
    inPremierLeague: false,
  },
  {
    value: "62c80a5e72f8e270f2253ebf",
    display: "Nottingham Forest",
    logo: "not.png",
    inPremierLeague: true,
  },
  {
    value: "61a4fd7cf9b222ad6d8ba4ae",
    display: "Premier League",
    logo: "pl.png",
    inPremierLeague: true,
  },
  {
    value: "64899b6402304b1d27da382a",
    display: "Sheffield United",
    logo: "shu.png",
    inPremierLeague: true,
  },
  {
    value: "61aa601c8bb9411344354060",
    display: "Southampton",
    logo: "sou.png",
    inPremierLeague: false,
  },
  {
    value: "61aa602f8bb9411344354066",
    display: "Tottenham Hotspur",
    logo: "tot.png",
    inPremierLeague: true,
  },
  {
    value: "61aa61998bb9411344354077",
    display: "Watford",
    logo: "wat.png",
    inPremierLeague: false,
  },
  {
    value: "61aa603e8bb941134435406c",
    display: "West Ham United",
    logo: "whu.png",
    inPremierLeague: true,
  },
  {
    value: "61aa60448bb941134435406f",
    display: "Wolverhampton Wanderers",
    logo: "wol.png",
    inPremierLeague: true,
  },
];

const getClubInputOptions = () =>
  clubArray
    .filter((club) => club.inPremierLeague)
    .map((club) => (
      <Option key={club.value} value={club.value} display={club.display}>
        <SOptionLogoWrapper>
          <Avatar
            size="small"
            src={`/club_logos/48/${club.logo}`}
            alt="avatar"
          />
          {club.display}
        </SOptionLogoWrapper>
      </Option>
    ));

export default getClubInputOptions;
