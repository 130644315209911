import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, Form, Button, List, Input } from "antd";
import dayjs from "dayjs";

import styled from "styled-components";
import { Comment } from "@ant-design/compatible";
import { JournalComment } from "../types";

const { TextArea } = Input;

const SCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    min-width: 650px;
  }
`;

const CommentList = ({ comments }: { comments: JournalComment[] }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "comments" : "comment"
    }`}
    itemLayout="horizontal"
    renderItem={({ comment, date, user }: JournalComment) => {
      return (
        <Comment
          author={user.email}
          avatar={`/club_logos/48/${user.company.logo}`}
          content={comment}
          datetime={dayjs(date).format("Do MMM, YYYY, h:mm:ss a")}
        />
      );
    }}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }: any) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="button"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const Comments = ({ record }: { record: string }) => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newComment, setNewComment] = useState({
    submitting: false,
    comment: "",
  });

  const fetchData = () => {
    axios
      .get(`/comment?record=${record}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          setComments(res.data.results);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [record]);

  const handleChange = (e: any) => {
    setNewComment({
      ...newComment,
      comment: e.target.value,
    });
  };

  const handleSubmit = () => {
    setNewComment({
      ...newComment,
      submitting: true,
    });
    axios
      .post(`/comment`, {
        record: record,
        comment: newComment.comment,
      })
      .then((res) => {
        fetchData();
        setNewComment({
          submitting: false,
          comment: "",
        });
      });
  };

  return (
    <SCommentWrapper>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          {comments.length > 0 && <CommentList comments={comments} />}
          <Comment
            content={
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={newComment.submitting}
                value={newComment.comment}
              />
            }
          />
        </>
      )}
    </SCommentWrapper>
  );
};

export default Comments;
