import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Card, Form, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";

import { SaveOutlined } from "@ant-design/icons";
import PartnershipForm from "../../components/PartnershipForm";
import styled from "styled-components";
import { Auth } from "../../types";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
`;

const FormNewStaging = ({ auth }: { auth: Auth }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const location = useLocation();
  const [tempId, setTempId] = useState("");

  const search = location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const formType = params.get("form");

  const routes = {
    items: [
      { title: "Transactions", path: "transaction" },
      { title: "New", path: "new" },
    ],
  };

  //get temp ID
  useEffect(() => {
    axios.get(`/term/new`).then((res) => {
      setTempId(res.data);
    });
  }, []);

  //get form data
  useEffect(() => {
    axios.get(`/company`).then((res) => {
      if (res.data) {
        var outp: any = [];
        res.data.forEach((company: any) => {
          outp.push({
            value: company._id,
            display: company.name,
            logo: company.logo,
          });
        });
        setCompanies(outp);
      }
    });
  }, []);

  //save data
  const saveData = (data: any) => {
    setFormLoading(true);

    const hide = message.loading("Saving..", 1.0);

    axios.post(`/term`, data).then((res) => {
      //get company name
      axios.get(`/company/${res.data.newTerm.company}`).then((res2) => {
        //migrate attachments
        axios
          .patch(`/upload/move`, {
            termNumber: `CDB${res.data.newTerm.number}`,
            clubId: res.data.newTerm.company,
            tmpId: tempId,
            newId: res.data.newTerm._id,
          })
          .then((res3) => {
            hide();
            setFormLoading(false);
            message.success("Submitted", 2.5);
            navigate(`/transaction/${res.data.newTerm._id}`, {
              state: { id: res.data.newTerm._id },
            });
          });
      });
    });
  };
  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    saveData(values);
  };
  const onSaveClick = () => {
    const mandatoryFields = [
      "title",
      "description",
      "agreement_date",
      "contracting_party",
      "contracting_party_jurisdiction",
      "brand",
      "brand_sector",
      "subject_matter",
      "associated_party",
      "arms_length",
      "summary",
      "commencement_date",
      "term",
      "strategic_rationale",
      "fees_total",
      "fees_cash",
      "fees_in_kind",
      "commercial_incentives",
      "fees_royalties",
      "associated_fees",
      "partner_designations",
      "exclusive_categories",
      "non_exclusive_categories",
      "territories",
      "assets_provided",
      "services_provided",
      "partner_rights",
    ];
    let formComplete = true;
    for (var i = 0; i < mandatoryFields.length; i++) {
      const value = form.getFieldValue(mandatoryFields[i]);
      if (value !== 0 && (!value || value === null || value.length === 0)) {
        formComplete = false;
      }
    }
    if (!formComplete) {
      message.error("Form incomplete.", 3.0);
    } else {
      form.validateFields();
      form.submit();
    }
  };

  return (
    <>
      <SPageHeader
        title={"New Transaction"}
        breadcrumb={routes}
        extra={[
          <Button
            loading={formLoading}
            type="primary"
            key={0}
            onClick={() => onSaveClick()}
            icon={<SaveOutlined />}
          >
            Submit
          </Button>,
        ]}
      ></SPageHeader>
      <SPageContent>
        <Card>
          <PartnershipForm
            {...formItemLayout}
            form={form}
            companies={companies}
            tempId={tempId}
            canWrite
            newRecord={true}
            userRole={auth.user.role}
            onFinish={onFormFinish}
            scrollToFirstError
          />
        </Card>
      </SPageContent>
    </>
  );
};

const mapStateToProps = (state: { auth: Auth }) => ({
  auth: state.auth,
});

const FormNew = connect(mapStateToProps, null)(FormNewStaging);

export default FormNew;
